import { useEffect, useState } from "react";
import { useImx } from "../contexts/ImxContext";
import { handleError } from "../utils/utils";
import { CurrentEvents } from "../clients/types";

export default function useCurrentEvents(): CurrentEvents {
  const { localDatabase, eventClient } = useImx();
  const [currentEvents, setCurrentEvents] = useState();

  useEffect(() => {
    const loadCurrentEvents = async () => {
      const currentEvents = await eventClient.getCurrentEvents();
      setCurrentEvents(currentEvents);
    };

    const refresh = () => {
      loadCurrentEvents().catch(handleError);
    };

    refresh();
    localDatabase.onEventsChange(refresh);
    return () => {
      localDatabase.offEventsChange(refresh);
    };
  }, []);

  return (
    currentEvents || {
      sale: undefined,
      openPack: undefined,
      farmingEvent: undefined,
    }
  );
}
