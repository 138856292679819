import styled from "styled-components";

export const ImxLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  border-radius: 5px;
  padding: 2rem;
  min-height: 400px;
`;

export const Error = styled.p`
  color: #f53855;
  border: 1px solid #f53855;
  padding: 1rem;
  font-size: 1.2rem;
`;

export const Loading = styled.div`
  text-align: center;
  color: var(--cyan);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 30px;
  font-weight: 400;
`;

export const LoadingMessage = styled.div`
  font-size: 1.5rem;
`;

export const Spinner = styled.div`
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(97, 36, 125, 0.5);
  border-right: 1.1em solid rgba(97, 36, 125, 0.5);
  border-bottom: 1.1em solid rgba(97, 36, 125, 0.5);
  border-left: 1.1em solid rgba(97, 36, 125, 1);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  &,
  &:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }

  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
`;

export const ActionButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  background-color: var(--cyan);
  font-family: "Barlow Condensed", sans-serif;
  font-size: 30px;
  font-weight: 400;
  height: 60px;
  width: 100%;
  text-transform: uppercase;
  transition: filter 200ms ease-in-out;

  & img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
  }

  :hover {
    filter: saturate(2);
  }

  &[disabled] {
    opacity: 0.5;
    cursor: auto;
  }
`;

export const ActionSelect = styled.select`
  border: none;
  background: var(--background-secondary);
  color: white;
  font-size: 18px;
  padding: 14px 20px;
  margin-bottom: 20px;
  width: 100%;
  transition: filter 200ms ease-in-out;

  :hover {
    filter: saturate(2);
  }
`;

export const StepTitle = styled.h2`
  font-family: "Barlow Condensed", sans-serif;
  font-size: 45px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0.5em;
`;

export const Progress = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 -6px 30px -6px;
  div {
    width: 50%;
    height: 20px;
    background: var(--background-secondary);
    position: relative;
    border-radius: 10px;
    margin: 0 6px;
    overflow: hidden;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background: white;
      transition: width 500ms ease-in-out;
    }
  }
  div.empty::before {
    width: 0;
  }
  div.a-bit::before {
    width: 16%;
  }
  div.full::before {
    width: 100%;
  }
`;

export const Img100 = styled.img`
  width: 100%;
  margin-bottom: 20px;
`;
