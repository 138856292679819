/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 **/

import React from "react";
import CurrentEvents from "./src/components/CurrentEvents/CurrentEvents";
import LocalDatabase from "./src/clients/LocalDatabase";
import { FormspreeProvider } from "@formspree/react";
import { ImxProvider } from "./src/contexts/ImxContext";
import { Web3ReactProvider } from "@web3-react/core";
import { connectors } from "./src/utils/connectors";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "./src/css/common.scss";
import "./src/css/framework.scss";

const localDatabase = new LocalDatabase();

if (typeof window !== "undefined") {
  if (window.location.host === "badbunnies.xyz") {
    Sentry.init({
      dsn: "https://290077b43c1841fb9e40ab75c2f8c00a@o4504366448246784.ingest.sentry.io/4504366450475008",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.5,
      environment: "production",
      release: "imx-badbunnies@" + process.env.GATSBY_RELEASE,
    });
  } else if (window.location.host === "test-badbunnies-xyz.web.app") {
    Sentry.init({
      dsn: "https://290077b43c1841fb9e40ab75c2f8c00a@o4504366448246784.ingest.sentry.io/4504366450475008",
      integrations: [new BrowserTracing()],
      tracesSampleRate: 0.0,
      environment: "development",
      release: "imx-badbunnies@" + process.env.GATSBY_RELEASE,
    });
  }
}

export const wrapRootElement = ({ element }) => {
  return (
    <Web3ReactProvider connectors={connectors}>
      <FormspreeProvider project="2021950040423005245">
        <ImxProvider localDatabase={localDatabase}>
          {element}
          <CurrentEvents />
        </ImxProvider>
      </FormspreeProvider>
    </Web3ReactProvider>
  );
};
