import React from "react";
import { useImx } from "../../contexts/ImxContext";
import styled from "styled-components";

const WalletInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  background: var(--background-secondary);
  padding: 10px;
  margin-bottom: 20px;
  img {
    margin-right: 10px;
  }
  input {
    flex-grow: 1;
    border: none;
    background: none;
    color: white;
    font-size: 18px;
  }
  button {
    border: none;
    background: none;
    cursor: pointer;
    img {
      margin-right: 0;
      margin-left: 10px;
    }
  }
`;

const WalletInput = ({ disconnectable }) => {
  const { actionHandler, account, disconnect } = useImx();

  return (
    <WalletInputContainer>
      <img src="/assets/icons/gem.png" alt="Ethereum" />
      <input type="text" value={account ? `Wallet: ${account}` : ""} disabled={true} />
      {disconnectable && (
        <button onClick={() => actionHandler(disconnect)}>
          <img src="/assets/icons/cancel.png" alt="Cancel button" />
        </button>
      )}
    </WalletInputContainer>
  );
};

export default WalletInput;
