import React, { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { navigate } from "gatsby";
import moment from "moment";
import useCurrentEvents from "../../hooks/useCurrentEvents";
import useRewards from "../../hooks/useRewards";

const CurrentEvents = () => {
  const { sale, openPack } = useCurrentEvents();
  const rewards = useRewards();
  const [hiddenEvents, setHiddenEvents] = useState({});
  const [hideRewards, setHideReward] = useState(false);
  const now = moment();

  const hideEvent = (event, e) => {
    e.stopPropagation();
    e.preventDefault();
    const newHiddenEvents = { ...hiddenEvents };
    newHiddenEvents[event] = true;
    setHiddenEvents(newHiddenEvents);
  };

  const navigateHideEvent = (event, to, e) => {
    navigate(to);
    hideEvent(event, e);
  };

  const event = (element) => (
    <CSSTransition timeout={300} classNames="animate-event">
      {element}
    </CSSTransition>
  );

  const rewardsCount =
    rewards &&
    !hideRewards &&
    rewards
      .filter((r) => !r.claimed)
      .filter(
        (reward) =>
          (!reward.claimableSince && !reward.claimableUntil) ||
          (now.valueOf() >= moment(reward.claimableSince).valueOf() &&
            now.valueOf() < moment(reward.claimableUntil).valueOf())
      ).length;

  return (
    <div className="current-events">
      <TransitionGroup>
        {sale &&
          !hiddenEvents.sale &&
          sale.percentage >= 20 &&
          event(
            <button onClick={navigateHideEvent.bind(this, "sale", "/mint")} className="event">
              <button onClick={hideEvent.bind(this, "sale")} className="close">
                <i>⨯</i>
              </button>
              {(sale.visuals === "black-friday" && <span className="title">Black Friday!</span>) || (
                <span className="title">Sale!</span>
              )}
              <img src="/assets/packs/rare.png" />
              <span>-{sale.percentage}% sale</span>
            </button>
          )}
        {openPack &&
          !hiddenEvents.openPack &&
          event(
            <button onClick={navigateHideEvent.bind(this, "openPack", "/mint")} className="event">
              <button onClick={hideEvent.bind(this, "openPack")} className="close">
                <i>⨯</i>
              </button>
              {openPack.visuals === "halloween" && (
                <>
                  <span className="title">Halloween event!</span>
                  <img src="/assets/packs/halloween.png" alt="Halloween event" />
                  <span>Special cards</span>
                </>
              )}
            </button>
          )}
        {rewardsCount > 0 &&
          event(
            <button
              onClick={() => {
                navigate(`/my/rewards`);
                setHideReward(true);
              }}
              className="event"
            >
              <button onClick={() => setHideReward(true)} className="close">
                <i>⨯</i>
              </button>
              <span className="title">{rewardsCount > 1 ? `${rewardsCount} rewards` : `Reward pending`}</span>
              <img src="/assets/images/reward.png" />
              <span>Claim now!</span>
            </button>
          )}
      </TransitionGroup>
    </div>
  );
};

export default CurrentEvents;
