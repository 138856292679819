import React, { FunctionComponent } from "react";

import StepConnectEthereum from "./steps/StepConnectEthereum";
import StepRegisterImmutableX from "./steps/StepRegisterImmutableX";
import { Loading, LoadingMessage, Spinner } from "./ImxLogin.elements";
import { useImx } from "../../contexts/ImxContext";

const ImxLogin: FunctionComponent = () => {
  const { loading, loadingMessage, loginFlags } = useImx();

  if (loading) {
    return (
      <Loading>
        <Spinner />
        <LoadingMessage>{loadingMessage}</LoadingMessage>
      </Loading>
    );
  }
  if (!loginFlags.accountStored || !loginFlags.ethereumConnected) {
    return <StepConnectEthereum />;
  }
  if (!loginFlags.imxRegistered || !loginFlags.imxUnlocked) {
    return <StepRegisterImmutableX />;
  }
  return <p>Wallet connected</p>;
};

export default ImxLogin;
