import { Db, Collection } from "zangodb";
import { BigNumber } from "ethers";
import { guidFrom, variationFull } from "../utils/utils";
import EventHandler from "eventhandler"; // TODO replace with a propper package
import {
  UnifiedAssetType,
  UnifiedAsset,
  Token,
  Reward,
  Deck,
  Event,
  MintEntry,
  FarmingStatePlayer,
  FarmingStateFarm,
  FarmingStateBunny,
  PaymentTxs,
} from "./types";

type MetadataVersion = {
  key: string;
  value: number;
};

export default class LocalDatabase {
  db: Db;
  eventHandler: EventHandler;

  constructor() {
    this.db = new Db("paw-warz", 11, {
      v2assetCache: {
        type: true,
        uid: true,
        tokenId: true,
        account: true,
      },
      v2eventCache: true,
      v2tokensCache: true,
      v2rewardCache: {
        rewardId: true,
        account: true,
      },
      v3mintJournal: {
        id: true,
        account: true,
      },
      v2metadata: {
        key: true,
        account: true,
      },
      v2deckCache: {
        deckId: true,
        account: true,
      },
      v2farmingPlayerCache: {
        account: true,
      },
      v2farmingFarmCache: {
        account: true,
        farmId: true,
      },
      v2farmingBunnyCache: {
        account: true,
        bunnyId: true,
      },
    });
    this.eventHandler = new EventHandler();
  }

  async clearAll(): Promise<void> {
    const collectionExists = (name: string): Collection | undefined => {
      try {
        return this.db.collection(name);
      } catch (e: any) {
        if (e.toString().includes("does not exist")) {
          return undefined;
        } else {
          throw e;
        }
      }
    };

    const assetCache = collectionExists("v2assetCache");
    const eventCache = collectionExists("v2eventCache");
    const tokensCache = collectionExists("v2tokensCache");
    const rewardCache = collectionExists("v2rewardCache");
    const metadata = collectionExists("v2metadata");
    const deckCache = collectionExists("v2deckCache");
    const farmingPlayerCache = collectionExists("v2farmingPlayerCache");

    const arr = [
      assetCache,
      eventCache,
      tokensCache,
      rewardCache,
      // exlude mintJournal as it stores any pending transactions
      metadata,
      deckCache,
      farmingPlayerCache,
    ].filter((x) => x !== undefined) as Array<Collection>;

    await Promise.all(arr.map((collection) => collection.remove({})));
  }

  async ensureMintJournalEntry(mintEntry: MintEntry, account: string): Promise<MintEntry> {
    const mintJournal = this.db.collection("v3mintJournal");

    console.info(`Ensuring mint journal entry ${mintEntry.id} for ${account}`);

    const found = (await mintJournal
      .find({
        id: { $eq: mintEntry.id },
        account: { $eq: account },
      })
      .toArray()) as Array<MintEntry>;
    if (found.length === 0) {
      const serializedMintEntry = {
        ...mintEntry,
        payment: {
          ...mintEntry.payment,
          eth: mintEntry.payment.eth?.toString(),
          fuseFarms: mintEntry.payment.fuseFarms
            ? {
                farms: mintEntry.payment.fuseFarms.farms,
                eth: mintEntry.payment.fuseFarms.eth.toString(),
              }
            : undefined,
        },
        account,
      };
      console.info(`Creating new mint jounal entry:\n${JSON.stringify(serializedMintEntry, null, 2)}`);
      await mintJournal.insert(serializedMintEntry);
      return mintEntry;
    } else {
      const existingMintEntry = {
        ...found[0],
        payment: {
          ...found[0].payment,
          eth: found[0].payment.eth ? BigNumber.from(found[0].payment.eth) : undefined,
          fuseFarms: found[0].payment.fuseFarms
            ? {
                farms: found[0].payment.fuseFarms.farms,
                eth: BigNumber.from(found[0].payment.fuseFarms.eth),
              }
            : undefined,
        },
      };
      console.info(`Mint journal entry already exists:\n${JSON.stringify(existingMintEntry, null, 2)}`);
      return existingMintEntry;
    }
  }

  async getMintJournalEntries(account: string): Promise<Array<MintEntry>> {
    const mintJournal = this.db.collection("v3mintJournal");
    return (await mintJournal.find({ account: { $eq: account } }).toArray()) as Array<MintEntry>;
  }

  async updateMintJournalEntryPaymentTxs(id: string, txs: PaymentTxs, account: string): Promise<void> {
    const mintJournal = this.db.collection("v3mintJournal");

    console.info(`Mint journal entry ${id} changing:\n${JSON.stringify(txs, null, 2)}`);

    await mintJournal.update(
      {
        id: { $eq: id },
        account: { $eq: account },
      },
      {
        txs: txs,
      }
    );
  }

  async resolveMintJournalEntry(id: string, account: string): Promise<void> {
    const mintJournal = this.db.collection("v3mintJournal");
    await mintJournal.remove({
      id: { $eq: id },
      account: { $eq: account },
    });
  }

  async getAssets(type: UnifiedAssetType, account: string): Promise<Array<UnifiedAsset>> {
    const assetCache = this.db.collection("v2assetCache");
    return (await assetCache
      .find({
        type: { $eq: type },
        account: { $eq: account },
      })
      .toArray()) as Array<UnifiedAsset>;
  }

  async getAssetAny(type: UnifiedAssetType, typeId: number, account: string): Promise<UnifiedAsset> {
    const assetCache = this.db.collection("v2assetCache");
    const found = await assetCache
      .find({
        type: { $eq: type },
        typeId: { $eq: typeId },
        account: { $eq: account },
      })
      .toArray();
    if (found.length === 0) {
      throw new Error(`Asset ${type} ${typeId} not found`);
    }
    return found[0] as UnifiedAsset;
  }

  async _addAsset(asset: UnifiedAsset, account: string): Promise<void> {
    const assetCache = this.db.collection("v2assetCache");
    const assetUid = guidFrom({
      type: asset.type,
      tokenId: asset.tokenId,
    });
    const found =
      (
        await assetCache
          .find({
            assetUid: { $eq: assetUid },
            account: { $eq: account },
          })
          .toArray()
      ).length > 0;
    if (found) {
      console.debug("Asset already exists", asset);
      return;
    }
    await assetCache.insert({
      type: asset.type,
      typeId: asset.typeId,
      variation: variationFull(asset.variation),
      tokenId: asset.tokenId,
      account: account,
      assetUid: assetUid,
    });
  }

  async addAsset(asset: UnifiedAsset, account: string): Promise<void> {
    await this._addAsset(asset, account);
    this.eventHandler.emit("assets", {
      types: [asset.type],
    });
  }

  async _removeAsset(asset: UnifiedAsset, account: string): Promise<void> {
    const assetCache = this.db.collection("v2assetCache");
    const assetUid = guidFrom({
      type: asset.type,
      tokenId: asset.tokenId,
    });
    const found =
      (
        await assetCache
          .find({
            assetUid: { $eq: assetUid },
            account: { $eq: account },
          })
          .toArray()
      ).length > 0;
    if (!found) {
      console.debug("Asset not found", asset);
      return;
    }
    await assetCache.remove({
      assetUid: { $eq: assetUid },
      account: { $eq: account },
    });
  }

  async removeAsset(asset: UnifiedAsset, account: string): Promise<void> {
    await this._removeAsset(asset, account);
    this.eventHandler.emit("assets", {
      types: [asset.type],
    });
  }

  async refreshAssets(assets: Array<UnifiedAsset>, account: string, version: number): Promise<void> {
    const assetsCache = this.db.collection("v2assetCache");
    await assetsCache.remove({});
    if (assets.length > 0) {
      await assetsCache.insert(
        assets.map((asset) => ({
          type: asset.type,
          typeId: asset.typeId,
          variation: variationFull(asset.variation),
          tokenId: asset.tokenId,
          account: account,
          assetUid: guidFrom({
            type: asset.type,
            tokenId: asset.tokenId,
          }),
        }))
      );
    }
    await this.refreshMetadataVersion(`assets.${account}`, version);
    const changedTypes = assets.reduce((acc: Array<UnifiedAssetType>, asset) => {
      if (!acc.includes(asset.type)) {
        acc.push(asset.type);
      }
      return acc;
    }, []);
    this.eventHandler.emit("assets", {
      types: changedTypes,
    });
  }

  async getAssetsVersion(account: string): Promise<number | undefined> {
    return await this.getMetadataVersion(`assets.${account}`);
  }

  onAssetsChange(callback: () => void) {
    this.eventHandler.on("assets", callback);
  }

  offAssetsChange(callback: () => void) {
    this.eventHandler.removeEventListener("assets", callback);
  }

  async refreshEvents(events: Array<Event>): Promise<void> {
    const eventCache = this.db.collection("v2eventCache");
    await eventCache.remove({});
    if (events.length > 0) {
      await eventCache.insert(events);
    }
    await this.refreshMetadataVersion("events", Date.now());
    this.eventHandler.emit("events", {});
  }

  async getEventsVersion(): Promise<number | undefined> {
    return await this.getMetadataVersion("events");
  }

  async getEvents(): Promise<Array<Event>> {
    const eventCache = this.db.collection("v2eventCache");
    return (await eventCache.find({}).toArray()) as Array<Event>;
  }

  onEventsChange(callback: () => void) {
    this.eventHandler.on("events", callback);
  }

  offEventsChange(callback: () => void) {
    this.eventHandler.removeEventListener("events", callback);
  }

  async refreshTokens(tokens: Array<Token>): Promise<void> {
    const tokensCache = this.db.collection("v2tokensCache");
    await tokensCache.remove({});
    if (tokens.length > 0) {
      await tokensCache.insert(tokens);
    }
    await this.refreshMetadataVersion("tokens", Date.now());
    this.eventHandler.emit("tokens", {});
  }

  async getTokensVersion(): Promise<number | undefined> {
    return await this.getMetadataVersion("tokens");
  }

  async getTokens(): Promise<Array<Token>> {
    const tokensCache = this.db.collection("v2tokensCache");
    return (await tokensCache.find({}).toArray()) as Array<Token>;
  }

  async refreshRewards(rewards: Array<Reward>, account: string): Promise<void> {
    const rewardCache = this.db.collection("v2rewardCache");
    await rewardCache.remove({});
    if (rewards.length > 0) {
      await rewardCache.insert(
        rewards.map((reward) => ({
          ...reward,
          account: account,
        }))
      );
    }
    await this.refreshMetadataVersion(`rewards.${account}`, Date.now());
    this.eventHandler.emit("rewards", {});
  }

  async storeRewardClaimed(rewardId: number, account: string): Promise<void> {
    const rewardCache = this.db.collection("v2rewardCache");
    await rewardCache.update(
      {
        rewardId: { $eq: rewardId },
        account: { $eq: account },
      },
      {
        claimed: true,
      }
    );
    await this.refreshMetadataVersion(`rewards.${account}`, Date.now());
    this.eventHandler.emit("rewards", {});
  }

  async getRewardsVersion(account: string): Promise<number | undefined> {
    return await this.getMetadataVersion(`rewards.${account}`);
  }

  async getRewards(account: string): Promise<Array<Reward>> {
    const rewardCache = this.db.collection("v2rewardCache");
    return (await rewardCache
      .find({
        account: { $eq: account },
      })
      .toArray()) as Array<Reward>;
  }

  onRewardsChange(callback: () => void) {
    this.eventHandler.on("rewards", callback);
  }

  offRewardsChange(callback: () => void) {
    this.eventHandler.removeEventListener("rewards", callback);
  }

  async refreshDecks(decks: Array<Deck>, account: string): Promise<void> {
    const deckCache = this.db.collection("v2deckCache");
    await deckCache.remove({});
    if (decks.length > 0) {
      await deckCache.insert(
        decks.map((deck) => ({
          ...deck,
          account: account,
        }))
      );
    }
    await this.refreshMetadataVersion(`deck.${account}`, Date.now());
    this.eventHandler.emit("decks", {});
  }

  async saveDeck(deck: Deck, account: string): Promise<void> {
    const deckCache = this.db.collection("v2deckCache");
    await deckCache.update(
      {
        deckId: { $eq: deck.deckId },
        account: { $eq: account },
      },
      {
        ...deck,
        account,
      }
    );
    await this.refreshMetadataVersion(`decks.${account}`, Date.now());
    this.eventHandler.emit("decks", {});
  }

  async getDecksVersion(account: string): Promise<number | undefined> {
    return await this.getMetadataVersion(`decks.${account}`);
  }

  async getDecks(account: string): Promise<Array<Deck>> {
    const deckCache = this.db.collection("v2deckCache");
    return (await deckCache
      .find({
        account: { $eq: account },
      })
      .toArray()) as Array<Deck>;
  }

  onDecksChange(callback: () => void) {
    this.eventHandler.on("decks", callback);
  }

  offDecksChange(callback: () => void) {
    this.eventHandler.removeEventListener("decks", callback);
  }

  async saveFarmingPlayer(farmingPlayer: FarmingStatePlayer, account: string): Promise<void> {
    const farmingPlayerCache = this.db.collection("v2farmingPlayerCache");
    await farmingPlayerCache.remove({
      account: { $eq: account },
    });
    await farmingPlayerCache.insert([
      {
        ...farmingPlayer,
        account: account,
      },
    ]);
    await this.refreshMetadataVersion(`farming-player.${account}`, Date.now());
    this.eventHandler.emit("farming-player", {});
  }

  async getFarmingPlayersVersion(account: string): Promise<number | undefined> {
    return await this.getMetadataVersion(`farming-player.${account}`);
  }

  async getFarmingPlayer(account: string): Promise<FarmingStatePlayer | undefined> {
    const farmingPlayerCache = this.db.collection("v2farmingPlayerCache");
    const found = (await farmingPlayerCache
      .find({
        account: { $eq: account },
      })
      .toArray()) as Array<FarmingStatePlayer>;
    if (found.length === 0) {
      return undefined;
    } else {
      return found[0];
    }
  }

  onFarmingPlayerChange(callback: () => void) {
    this.eventHandler.on("farming-player", callback);
  }

  offFarmingPlayerChange(callback: () => void) {
    this.eventHandler.removeEventListener("farming-player", callback);
  }

  async refreshFarmingFarms(
    farmingFarmsData: Array<{
      state: FarmingStateFarm;
      farmId: number;
    }>,
    account: string
  ): Promise<void> {
    const farmingFarmCache = this.db.collection("v2farmingFarmCache");
    await farmingFarmCache.remove({
      account: { $eq: account },
    });
    if (farmingFarmsData.length > 0) {
      await farmingFarmCache.insert(
        farmingFarmsData.map(({ state, farmId }) => ({
          ...state,
          farmId: farmId,
          account: account,
        }))
      );
    }
    this.eventHandler.emit("farming-farm", {
      farmIds: farmingFarmsData.map(({ farmId }) => farmId),
    });
  }

  async saveFarmingFarm(farmingFarm: FarmingStateFarm, farmId: number, account: string): Promise<void> {
    const farmingFarmCache = this.db.collection("v2farmingFarmCache");
    await farmingFarmCache.remove({
      account: { $eq: account },
      farmId: { $eq: farmId },
    });
    await farmingFarmCache.insert({
      ...farmingFarm,
      farmId: farmId,
      account: account,
    });
    this.eventHandler.emit("farming-farm", {
      farmIds: [farmId],
    });
  }

  async getFarmingFarms(account: string): Promise<Array<FarmingStateFarm>> {
    const farmingFarmCache = this.db.collection("v2farmingFarmCache");
    return (await farmingFarmCache
      .find({
        account: { $eq: account },
      })
      .toArray()) as Array<FarmingStateFarm>;
  }

  async getFarmingFarm(farmId: number, account: string): Promise<FarmingStateFarm | undefined> {
    const farmingFarmCache = this.db.collection("v2farmingFarmCache");
    const found = (await farmingFarmCache
      .find({
        account: { $eq: account },
        farmId: { $eq: farmId },
      })
      .toArray()) as Array<FarmingStateFarm>;
    if (found.length === 0) {
      return undefined;
    } else {
      return found[0];
    }
  }

  onFarmingFarmChange(callback: () => void) {
    this.eventHandler.on("farming-farm", callback);
  }

  offFarmingFarmChange(callback: () => void) {
    this.eventHandler.removeEventListener("farming-farm", callback);
  }

  async refreshFarmingBunnies(
    farmingBunniesData: Array<{
      state: FarmingStateBunny;
      bunnyId: number;
    }>,
    account: string
  ): Promise<void> {
    const farmingBunnyCache = this.db.collection("v2farmingBunnyCache");
    await farmingBunnyCache.remove({
      account: { $eq: account },
    });
    if (farmingBunniesData.length > 0) {
      await farmingBunnyCache.insert(
        farmingBunniesData.map(({ state, bunnyId }) => ({
          ...state,
          bunnyId: bunnyId,
          account: account,
        }))
      );
    }
    this.eventHandler.emit("farming-bunny", {
      bunnyIds: farmingBunniesData.map(({ bunnyId }) => bunnyId),
    });
  }

  async saveFarmingBunny(farmingBunny: FarmingStateBunny, bunnyId: number, account: string): Promise<void> {
    const farmingBunnyCache = this.db.collection("v2farmingBunnyCache");
    await farmingBunnyCache.remove({
      account: { $eq: account },
      bunnyId: { $eq: bunnyId },
    });
    await farmingBunnyCache.insert({
      ...farmingBunny,
      bunnyId: bunnyId,
      account: account,
    });
    this.eventHandler.emit("farming-bunny", {
      bunnyIds: [bunnyId],
    });
  }

  async getFarmingBunnies(account: string): Promise<Array<FarmingStateBunny>> {
    const farmingBunnyCache = this.db.collection("v2farmingBunnyCache");
    return (await farmingBunnyCache
      .find({
        account: { $eq: account },
      })
      .toArray()) as Array<FarmingStateBunny>;
  }

  async getFarmingBunny(bunnyId: number, account: string): Promise<FarmingStateBunny | undefined> {
    const farmingBunnyCache = this.db.collection("v2farmingBunnyCache");
    const found = (await farmingBunnyCache
      .find({
        account: { $eq: account },
        bunnyId: { $eq: bunnyId },
      })
      .toArray()) as Array<FarmingStateBunny>;
    if (found.length === 0) {
      return undefined;
    } else {
      return found[0];
    }
  }

  onFarmingBunnyChange(callback: () => void) {
    this.eventHandler.on("farming-bunny", callback);
  }

  offFarmingBunnyChange(callback: () => void) {
    this.eventHandler.removeEventListener("farming-bunny", callback);
  }

  async getRemoteActionsVersion(account: string): Promise<number | undefined> {
    return await this.getMetadataVersion(`remote-actions.${account}`);
  }

  async refreshRemoteActionsVersion(account: string): Promise<void> {
    return await this.refreshMetadataVersion(`remote-actions.${account}`, Date.now());
  }

  async refreshMetadataVersion(key: string, version: number): Promise<void> {
    const metadata = this.db.collection("v2metadata");
    const existingVersion = ((await metadata
      .find({
        key: { $eq: key },
      })
      .toArray()) || [null])[0] as MetadataVersion | undefined;
    if (existingVersion) {
      await metadata.update(
        { key: { $eq: key } },
        {
          value: version,
        }
      );
    } else {
      await metadata.insert({
        key: key,
        value: version,
      });
    }
  }

  async getMetadataVersion(key: string): Promise<number | undefined> {
    const metadata = this.db.collection("v2metadata");
    const existingVersion = ((await metadata
      .find({
        key: { $eq: key },
      })
      .toArray()) || [null])[0] as MetadataVersion;
    if (existingVersion) {
      return existingVersion.value;
    } else {
      return undefined;
    }
  }
}
