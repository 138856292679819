import { BigNumber, utils } from "ethers";
import { MerkleTree } from "merkletreejs";
import whitelist from "../data/farm-whitelist.json";
import EthereumClient from "./EthereumClient";
import LocalDatabase from "./LocalDatabase";
import PawWarzApiClient from "./PawWarzApiClient";
import { Context, Actions, FarmingActionResult } from "./types";
import { toast } from "react-toastify";

class FarmingClient {
  context: Context;
  actions: Actions;
  localDatabase: LocalDatabase;
  ethereumClient: EthereumClient;
  pawWarzApiClient: PawWarzApiClient;

  constructor(
    context: Context,
    actions: Actions,
    localDatabase: LocalDatabase,
    ethereumClient: EthereumClient,
    pawWarzApiClient: PawWarzApiClient
  ) {
    this.context = context;
    this.actions = actions;
    this.localDatabase = localDatabase;
    this.ethereumClient = ethereumClient;
    this.pawWarzApiClient = pawWarzApiClient;
  }

  async refreshFarmingPlayer(account: string): Promise<void> {
    const { player, allFarms, allBunnies } = await this.pawWarzApiClient.getFarmingPlayer(account);
    this.localDatabase.saveFarmingPlayer(player.state, account);
    this.localDatabase.refreshFarmingFarms(allFarms, account);
    this.localDatabase.refreshFarmingBunnies(allBunnies, account);
  }

  async getMintInfo(): Promise<{
    startTime: number | undefined;
    startTimeWhitelist: number | undefined;
    price: BigNumber | undefined;
  }> {
    const farmMinter = this.ethereumClient.getFarmMinterContract();
    try {
      return {
        startTime: await farmMinter.startTime(),
        startTimeWhitelist: await farmMinter.startTimeWhitelist(),
        price: await farmMinter.mintPrice(),
      };
    } catch (e) {
      console.error(e);
      return {
        startTime: undefined,
        startTimeWhitelist: undefined,
        price: undefined,
      };
    }
  }

  getWhitelistProof(account: string): Array<string> {
    const tree = new MerkleTree(whitelist, utils.keccak256, {
      hashLeaves: true,
      sortLeaves: true,
      sortPairs: true,
    });
    const leaf = utils.keccak256(account);
    const proof = tree.getHexProof(leaf);
    if (proof.length === 0) {
      throw new Error("Your address isn't on the whitelist");
    }
    return proof;
  }

  async mintWhitelist(amount: number): Promise<void> {
    const farmMinter = this.ethereumClient.getFarmMinterContract();
    const merkleProof = this.getWhitelistProof(this.context.account);
    const { price } = await this.getMintInfo();
    if (!price) {
      toast.error("Minting is not available at the moment");
      return;
    }
    this.actions.setLoadingMessage("Please, sign the mint transaction");
    const tx = await farmMinter.mintWhitelist(merkleProof, amount, {
      value: price.mul(BigNumber.from(amount)),
    });
    this.actions.setLoadingMessage("Waiting for transaction confirmation...");
    await this.context.provider!.waitForTransaction(tx.hash);
    return tx.hash;
  }

  async mint(amount: number): Promise<void> {
    const { price } = await this.getMintInfo();
    if (!price) {
      toast.error("Minting is not available at the moment");
      return;
    }
    this.actions.setLoadingMessage("Please, sign the mint transaction");
    const farmMinter = this.ethereumClient.getFarmMinterContract();
    const tx = await farmMinter.mint(amount, {
      value: price.mul(BigNumber.from(amount)),
    });
    this.actions.setLoadingMessage("Waiting for transaction confirmation...");
    await this.context.provider!.waitForTransaction(tx.hash);
    return tx.hash;
  }

  async farmingAction(
    farmId: number | undefined,
    bunnyId: number | undefined,
    player: string | undefined,
    action: string,
    props: Record<string, any>
  ): Promise<FarmingActionResult> {
    await this.ethereumClient.connect();
    const result = await this.pawWarzApiClient.farmingAction(farmId, bunnyId, player, action, props);
    if (result.player) {
      await this.localDatabase.saveFarmingPlayer(result.player.state, this.context.account);
    }
    if (result.farm) {
      await this.localDatabase.saveFarmingFarm(result.farm.state, result.farm.farmId, this.context.account);
    }
    if (result.bunny) {
      await this.localDatabase.saveFarmingBunny(result.bunny.state, result.bunny.bunnyId, this.context.account);
    }
    return result;
  }

  async matchHijackShipment(): Promise<FarmingActionResult> {
    const result = await this.pawWarzApiClient.farmingMatchHijackShipment(this.context.account);
    if (result.player) {
      await this.localDatabase.saveFarmingPlayer(result.player.state, this.context.account);
    } else {
      toast.error("No shipment in progress to hijack! Try again later.");
    }
    return result;
  }

  async matchFarmingEvent(farmId: number): Promise<FarmingActionResult> {
    const result = await this.pawWarzApiClient.farmingMatchFarmingEvent(farmId);
    if (result.farm) {
      await this.localDatabase.saveFarmingFarm(result.farm.state, result.farm.farmId, this.context.account);
    }
    return result;
  }

  async buyFertilizer(amount: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "buyFertilizer", { amount });
  }

  async buyPesticide(amount: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "buyPesticide", {
      amount,
    });
  }

  async buyPickaxe(amount: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "buyPickaxe", {
      amount,
    });
  }

  async buyLockbox(amount: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "buyLockbox", {
      amount,
    });
  }

  async exchangeOnyxShards(amount: number, up: boolean): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "exchangeOnyxShards", {
      amount,
      up,
    });
  }

  async exchangeGoldNuggets(amount: number, up: boolean): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "exchangeGoldNuggets", {
      amount,
      up,
    });
  }

  async exchangeUncutDiamonds(amount: number, up: boolean): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "exchangeUncutDiamonds", {
      amount,
      up,
    });
  }

  async useCabbage(amount: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "useCabbage", {
      amount,
    });
  }

  async useOnyxShards(amount: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "useOnyxShards", {
      amount,
    });
  }

  async useGoldNuggets(amount: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "useGoldNuggets", {
      amount,
    });
  }

  async useUncutDiamonds(amount: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "useUncutDiamonds", {
      amount,
    });
  }

  async useWater(farmId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, undefined, undefined, "useWater", {});
  }

  async useFertilizer(farmId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, undefined, this.context.account, "useFertilizer", {});
  }

  async useScarecrow(farmId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, undefined, undefined, "useScarecrow", {});
  }

  async usePesticide(farmId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, undefined, this.context.account, "usePesticide", {});
  }

  async usePickaxe(farmId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, undefined, this.context.account, "usePickaxe", {});
  }

  async assignFarmer(farmId: number, bunnyId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, bunnyId, undefined, "assignFarmer", {});
  }

  async assignGuard(farmId: number, bunnyId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, bunnyId, undefined, "assignGuard", {});
  }

  async assignHijacker(bunnyId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, bunnyId, this.context.account, "assignHijacker", {});
  }

  async deassignFarmer(farmId: number, bunnyId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, bunnyId, undefined, "deassignFarmer", {});
  }

  async deassignGuard(farmId: number, bunnyId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, bunnyId, undefined, "deassignGuard", {});
  }

  async deassignHijacker(bunnyId: number): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, bunnyId, this.context.account, "deassignHijacker", {});
  }

  async shipment(
    farmId: number,
    cabbage: number,
    onyxShards: number,
    goldNuggets: number,
    uncutDiamonds: number,
    lockbox: boolean
  ): Promise<FarmingActionResult> {
    const cabbageCapo = Math.floor(cabbage * 0.05);
    return await this.farmingAction(farmId, undefined, this.context.account, "shipment", {
      cabbage: cabbage - cabbageCapo,
      cabbageCapo,
      onyxShards,
      goldNuggets,
      uncutDiamonds,
      lockbox,
    });
  }

  async finishShipment(farmId: number, to: string): Promise<FarmingActionResult> {
    return await this.farmingAction(farmId, undefined, to, "finishShipment", {});
  }

  async planHijack(): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "planHijack", {});
  }

  async finishHijack(): Promise<FarmingActionResult> {
    return await this.farmingAction(undefined, undefined, this.context.account, "finishHijack", {});
  }
}

export default FarmingClient;
