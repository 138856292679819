// eslint-disable-file jsx-a11y/click-events-have-key-events
import React, { FunctionComponent, ReactNode } from "react";

export type ModalType = {
  title: string,
  onClose: () => void,
  children: ReactNode|ReactNode[]
}

const Modal: FunctionComponent<ModalType> = ({ title, onClose, children }) => {
  return (
    <div className="modal-backdrop" onMouseDown={onClose}>
      <div
        className="modal"
        onMouseDown={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="modal-header">
          <h3>{title}</h3>
          <button onClick={onClose}>
            <img src="/assets/icons/cancel.png" alt="Close" />
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
};

export default Modal;
