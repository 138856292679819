import axios, { AxiosResponse } from "axios";
import { networkConfig } from "../utils/config";
import EthereumClient from "./EthereumClient";
import {
  MintResult,
  Reward,
  RewardItem,
  FarmingStateFarm,
  FarmingStatePlayer,
  FarmingStateBunny,
  FarmingShipments,
  UnifiedAsset,
  FarmingActionResult,
} from "./types";
import { Moment } from "moment";

class PawWarzApiClient {
  ethereumClient: EthereumClient;

  constructor(ethereumClient: EthereumClient) {
    this.ethereumClient = ethereumClient;
  }

  async mintPack(tx: string, pack: string, amount: number, ogHolderProof?: number): Promise<MintResult> {
    const body: Record<string, any> = {
      tx: tx,
      pack: pack,
      amount: amount,
    };
    if (ogHolderProof) {
      body["ogHolderProof"] = ogHolderProof;
    }
    const result = await axios.post(`${networkConfig.paw_warz_api}/mint/pack/`, body);
    return result.data;
  }

  async mintBattlepass(tx: string, battlepass: string): Promise<MintResult> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/mint/battlepass/`, {
      tx: tx,
      battlepass: battlepass,
    });
    return result.data;
  }

  async mintCabbage(tx: string, amount: number): Promise<MintResult> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/mint/cabbage/`, {
      tx: tx,
      amount: amount,
    });
    return result.data;
  }

  async mintOpenPack(tx: string): Promise<MintResult> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/mint/open-pack/`, {
      tx: tx,
    });
    return result.data;
  }

  async mintFuse(txs: Array<string>): Promise<MintResult> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/mint/fuse/`, {
      txs: txs,
    });
    return result.data;
  }

  async claimReward(rewardId: number): Promise<MintResult> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/claim-reward/`, {
      rewardId: rewardId,
    });
    return result.data;
  }

  async buy(orderId: number): Promise<MintResult> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/mediate/buy-order/`, {
      orderId: orderId,
    });
    return result.data;
  }

  async farmFuse(txs: Array<string>): Promise<MintResult> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/mediate/farm-fuse/`, {
      txs: txs,
    });
    return result.data;
  }

  async farmWasFused(farmId: number): Promise<void> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/mediate/farm-was-fused/`, {
      farmId: farmId,
    });
    return result.data;
  }

  async redeem(code: string): Promise<MintResult> {
    const body = {
      code: code,
    };
    const ownerSignature = await this.ethereumClient.signData(body);
    const result = await axios.post(`${networkConfig.paw_warz_api}/redeem/`, body, {
      params: { ownerSignature },
    });
    return result.data;
  }

  async giveReward(
    account: string,
    rewardTitle: string,
    rewardSince: string,
    rewardUntil: string,
    reward: Array<RewardItem>
  ): Promise<void> {
    const data = {
      account,
      rewardTitle,
      rewardSince,
      rewardUntil,
      reward,
    };
    const ownerSignature = await this.ethereumClient.signData(data);
    const result = await axios.post(`${networkConfig.paw_warz_api}/give-reward/`, data, {
      params: { ownerSignature },
    });
    return result.data;
  }

  async getPlayerInfo(account: string): Promise<{
    image: string;
  }> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/player/${account}/info`);
    return result.data;
  }

  async getPlayerAssets(account: string): Promise<Array<UnifiedAsset>> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/player/${account}/assets`);
    return result.data;
  }

  async getPlayerRewards(account: string): Promise<Array<Reward>> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/player/${account}/rewards`);
    return result.data;
  }

  async imageTraits(tokenId: number): Promise<any> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/image/traits/${tokenId}`);
    return result.data;
  }

  async imageConstructBunny(layout: string, traits: Record<string, string>): Promise<string> {
    const result = await axios.post(
      `${networkConfig.paw_warz_api}/image/construct-bunny/`,
      {
        layout: layout,
        traits: traits,
      },
      {
        responseType: "blob",
      }
    );

    const blobToDataUrl = (data: Blob): Promise<string> => {
      return new Promise((resolve, reject) => {
        const reader = new window.FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => {
          resolve(reader.result as string);
        };
        reader.onerror = reject;
      });
    };

    return await blobToDataUrl(result.data);
  }

  async customize(tokenId: number, traits: Record<string, string>): Promise<any> {
    const body = {
      traits: traits,
    };
    const ownerSignature = await this.ethereumClient.signData(body);
    const result = await axios.post(`${networkConfig.paw_warz_api}/customize/${tokenId}`, body, {
      params: { ownerSignature },
    });
    return result.data;
  }

  async farmingReveal(tx: string): Promise<any> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/farming/reveal/`, {
      tx: tx,
    });
    return result.data;
  }

  async getFarmMetadata(tokenId: number): Promise<any> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/farming/metadata/${tokenId}/`);
    return result.data;
  }

  async addEvent(event: any) {
    const ownerSignature = await this.ethereumClient.signData(event);
    const result = await axios.post(`${networkConfig.paw_warz_api}/event/`, event, {
      params: { ownerSignature },
    });
    return result.data;
  }

  async getEvents() {
    const result = await axios.get(`${networkConfig.paw_warz_api}/events/`);
    return result.data;
  }

  async getCloseEvents() {
    const result = await axios.get(`${networkConfig.paw_warz_api}/events/close/`);
    return result.data;
  }

  async getPlayerDecks(account: string) {
    const result = await axios.get(`${networkConfig.paw_warz_api}/player/${account}/decks`);
    return result.data;
  }

  async getDeck(deckId: string) {
    const result = await axios.get(`${networkConfig.paw_warz_api}/deck/${deckId}`);
    return result.data;
  }

  async saveDeck(deckId: string, name: string, tokens: Array<{ type: string; tokenId: number }>) {
    const body = {
      deckId: deckId,
      name: name,
      tokens: tokens,
    };
    const ownerSignature = await this.ethereumClient.signData(body);
    const result = await axios.post(`${networkConfig.paw_warz_api}/deck`, body, {
      params: { ownerSignature },
    });
    return result.data;
  }

  async deleteDeck(deckId: string): Promise<any> {
    const body = {
      deckId: deckId,
    };
    const ownerSignature = await this.ethereumClient.signData(body);
    const result = await axios.delete(`${networkConfig.paw_warz_api}/deck`, {
      params: { ownerSignature },
      data: body,
    });
    return result.data;
  }

  async cabbageShopBuy(itemId: string, txs: Array<string>, info?: Record<string, string>): Promise<any> {
    let data: {
      itemId: string;
      txs: Array<string>;
      info?: Record<string, string>;
    } = {
      itemId: itemId,
      txs: txs,
    };
    if (info) {
      data.info = info;
    }
    const result = await axios.post(`${networkConfig.paw_warz_api}/cabbage-shop/buy`, data);
    return result.data;
  }

  async getSettings(account: string): Promise<any> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/player/${account}/settings`);
    return result.data;
  }

  async saveSettings(settings: Record<string, string>): Promise<void> {
    const ownerSignature = await this.ethereumClient.signData(settings);
    const result = await axios.post(`${networkConfig.paw_warz_api}/settings`, settings, {
      params: { ownerSignature },
    });
    return result.data;
  }

  async cabbagePack(txs: Array<string>, amount: number): Promise<any> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/cabbage/pack`, {
      txs: txs,
      amount: amount,
    });
    return result.data;
  }

  async cabbageUnpack(txs: Array<string>, amount: number): Promise<any> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/cabbage/unpack`, {
      txs: txs,
      amount: amount,
    });
    return result.data;
  }

  async getPlayerBattlepasses(account: string): Promise<any> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/player/${account}/battlepass`);
    return result.data;
  }

  async claimBattlepassReward(account: string, battlepass: string, rewardAmount: number): Promise<any> {
    const body = {
      account: account,
      battlepass: battlepass,
      rewardAmount: rewardAmount,
    };
    const result = await axios.post(`${networkConfig.paw_warz_api}/battlepass/claim`, body);
    return result.data;
  }

  async getFarmingFarm(farmId: number): Promise<FarmingStateFarm> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/farming/farm/${farmId}`);
    return result.data.farm.state;
  }

  async getFarmingBunny(bunnyId: number): Promise<FarmingStateBunny> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/farming/bunny/${bunnyId}`);
    return result.data.bunny.state;
  }

  async getFarmingPlayer(player: string): Promise<{
    player: {
      state: FarmingStatePlayer;
      account: string;
    };
    allFarms: Array<{
      state: FarmingStateFarm;
      farmId: number;
    }>;
    allBunnies: Array<{
      state: FarmingStateBunny;
      bunnyId: number;
    }>;
  }> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/farming/player/${player}`);
    return result.data;
  }

  async getFarmingShipments(): Promise<FarmingShipments> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/farming/shipments`);
    return result.data;
  }

  async getFarmingAdmin(): Promise<any> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/farming/admin`);
    return result.data;
  }

  async farmingAdmingGive(
    player: string,
    cabbage: number,
    onyxShards: number,
    goldNuggets: number,
    uncutDiamonds: number
  ): Promise<any> {
    const data = {
      player: player,
      cabbage: cabbage,
      onyxShards: onyxShards,
      goldNuggets: goldNuggets,
      uncutDiamonds: uncutDiamonds,
    };
    const ownerSignature = await this.ethereumClient.signData(data);
    const result = await axios.post(`${networkConfig.paw_warz_api}/farming/admin/give`, data, {
      params: { ownerSignature },
    });
    return result.data;
  }

  async farmingAdmingCertified(
    action: string,
    farmId: number | undefined,
    bunnyId: number | undefined,
    player: string,
    props: any,
    timestamp: Moment
  ) {
    const data: Record<string, any> = {
      action,
      props,
      timestamp: timestamp.toISOString(),
    };
    if (farmId) data["farmId"] = farmId;
    if (bunnyId) data["bunnyId"] = bunnyId;
    if (player) data["player"] = player;
    const ownerSignature = await this.ethereumClient.signData(data);
    const result = await axios.post(`${networkConfig.paw_warz_api}/farming/admin/certified`, data, {
      params: { ownerSignature },
    });
    return result.data;
  }

  async farmingAdminFarmRefresh(farmId: number): Promise<any> {
    const ownerSignature = await this.ethereumClient.signData({});
    const result = await axios.post(
      `${networkConfig.paw_warz_api}/farming/admin/farm/${farmId}/refresh`,
      {},
      { params: { ownerSignature } }
    );
    return result.data;
  }

  async farmingAdminBunnyRefresh(bunnyId: number): Promise<any> {
    const ownerSignature = await this.ethereumClient.signData({});
    const result = await axios.post(
      `${networkConfig.paw_warz_api}/farming/admin/bunny/${bunnyId}/refresh`,
      {},
      { params: { ownerSignature } }
    );
    return result.data;
  }

  async farmingAdminPlayerRefresh(player: string): Promise<any> {
    const ownerSignature = await this.ethereumClient.signData({});
    const result = await axios.post(
      `${networkConfig.paw_warz_api}/farming/admin/player/${player}/refresh`,
      {},
      { params: { ownerSignature } }
    );
    return result.data;
  }

  async farmingAction(
    farmId: number | undefined,
    bunnyId: number | undefined,
    player: string | undefined,
    action: string,
    props: Record<string, any>
  ): Promise<FarmingActionResult> {
    const body: Record<string, any> = {
      action: action,
      props: props,
    };
    if (farmId) body["farmId"] = farmId;
    if (bunnyId) body["bunnyId"] = bunnyId;
    if (player) body["player"] = player;
    const ownerSignature = await this.ethereumClient.signData(body);
    const result = await axios.post(`${networkConfig.paw_warz_api}/farming/action`, body, {
      params: { ownerSignature },
    });
    return result.data;
  }

  async farmingMatchHijackShipment(player: string): Promise<FarmingActionResult> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/farming/match-hijack-shipment`, {
      player: player,
    });
    return result.data;
  }

  async farmingMatchFarmingEvent(farmId: number): Promise<FarmingActionResult> {
    const result = await axios.post(`${networkConfig.paw_warz_api}/farming/match-farming-event`, {
      farmId: farmId,
    });
    return result.data;
  }

  async getStats(): Promise<any> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/stats`);
    return result.data;
  }

  async getStatsTotalRewards(): Promise<{
    totalPrice: number;
    totalPriceCurrency: string;
  }> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/stats/total-rewards`);
    return result.data;
  }

  async getStatsLeaderboard(): Promise<{ player: string; score: number }> {
    const result = await axios.get(`${networkConfig.paw_warz_api}/stats/leaderboard`);
    return result.data;
  }

  async gameLogin(): Promise<{
    token: string;
    refreshToken: string;
    apiVersion: string;
  }> {
    const body = {};
    const ownerSignature = await this.ethereumClient.signData(body);
    const result = await axios.post(`${networkConfig.paw_warz_api}/game/login`, body, {
      params: { ownerSignature },
    });
    return result.data;
  }
}

export default PawWarzApiClient;
