import { UnifiedAssetType, UnifiedAsset, MergedAsset } from "./types";
import { guidFrom, variationFull } from "../utils/utils";
import LocalDatabase from "./LocalDatabase";
import PawWarzApiClient from "./PawWarzApiClient";

class AssetClient {
  localDatabase: LocalDatabase;
  pawWarzApiClient: PawWarzApiClient;

  constructor(localDatabase: LocalDatabase, pawWarzApiClient: PawWarzApiClient) {
    this.localDatabase = localDatabase;
    this.pawWarzApiClient = pawWarzApiClient;
  }

  async refreshAssets(account: string): Promise<void> {
    const assets = await this.pawWarzApiClient.getPlayerAssets(account);
    this.localDatabase.refreshAssets(assets, account, Date.now());
  }

  async getCabbageAmount(account: string): Promise<number> {
    const cabbageAssets = await this.localDatabase.getAssets("cabbage", account);
    return cabbageAssets.reduce((acc, cabbageAsset) => acc + cabbageAsset.typeId * 50, 0);
  }

  async getAsset(type: UnifiedAssetType, typeId: number, account: string): Promise<UnifiedAsset> {
    return await this.localDatabase.getAssetAny(type, typeId, account);
  }

  async getAssets(type: UnifiedAssetType, account: string): Promise<Array<UnifiedAsset>> {
    const assets = await this.localDatabase.getAssets(type, account);
    if (type === "og-card") {
      const originalBunnies = await this.localDatabase.getAssets("original-bunny", account);
      originalBunnies.forEach((originalBunny) => {
        assets.push({
          type: "og-card",
          typeId: originalBunny.typeId,
          tokenId: originalBunny.tokenId,
          variation: {
            frame: "diamond",
          },
        });
      });
    }
    return assets;
  }

  async getMergedAssets(type: UnifiedAssetType, account: string): Promise<Array<MergedAsset>> {
    const assets = await this.getAssets(type, account);
    const mergedAssets: Record<string, MergedAsset> = {};
    assets.forEach((asset) => {
      const mergeKey = `${asset.type}-${asset.typeId}-${guidFrom(variationFull(asset.variation))}`;
      if (mergedAssets[mergeKey]) {
        if (asset.tokenId) {
          mergedAssets[mergeKey].tokenIds.push(asset.tokenId);
        }
        mergedAssets[mergeKey].amount += 1;
      } else {
        mergedAssets[mergeKey] = {
          type: asset.type,
          typeId: asset.typeId,
          tokenIds: asset.tokenId ? [asset.tokenId] : [],
          variation: asset.variation,
          amount: 1,
        };
      }
    });
    return Object.values(mergedAssets);
  }

  async getLooseMergedAssets(type: UnifiedAssetType, account: string): Promise<Array<MergedAsset>> {
    const assets = await this.getAssets(type, account);
    const mergedAssets: Record<string, MergedAsset> = {};
    assets.forEach((asset) => {
      let mergeKey: string;
      if (type == "farm") {
        mergeKey = `${asset.type}-${guidFrom(variationFull(asset.variation))}`;
      } else {
        mergeKey = `${asset.type}-${asset.typeId}`;
      }
      if (mergedAssets[mergeKey]) {
        if (asset.tokenId) {
          mergedAssets[mergeKey].tokenIds.push(asset.tokenId);
        }
        mergedAssets[mergeKey].amount += 1;
        if (
          JSON.stringify(variationFull(asset.variation)).length >
          JSON.stringify(variationFull(mergedAssets[mergeKey].variation)).length
        ) {
          mergedAssets[mergeKey].variation = asset.variation;
        }
      } else {
        mergedAssets[mergeKey] = {
          type: asset.type,
          typeId: asset.typeId,
          variation: asset.variation,
          tokenIds: asset.tokenId ? [asset.tokenId] : [],
          amount: 1,
        };
      }
    });
    return Object.values(mergedAssets);
  }

  async getOriginalBunnyHolderProof(account: string): Promise<number | undefined> {
    const originalBunnies = await this.localDatabase.getAssets("original-bunny", account);
    if (originalBunnies.length > 0) {
      return originalBunnies[0].tokenId;
    } else {
      return undefined;
    }
  }
}

export default AssetClient;
