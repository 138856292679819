import { useEffect, useMemo } from "react";

import { Context, Actions } from "../clients/types";
import AssetActionsClient from "../clients/AssetActionsClient";
import DecksClient from "../clients/DecksClient";
import EthereumClient from "../clients/EthereumClient";
import EventClient from "../clients/EventClient";
import FarmingClient from "../clients/FarmingClient";
import AssetClient from "../clients/AssetClient";
import ImxClient from "../clients/ImxClient";
import PawWarzApiClient from "../clients/PawWarzApiClient";
import PriceClient from "../clients/PriceClient";
import PaymentClient from "../clients/PaymentClient";
import RewardClient from "../clients/RewardClient";
import TokensClient from "../clients/TokensClient";
import LocalDatabase from "../clients/LocalDatabase";

export default function useClients({
  account,
  link,
  client,
  connector,
  provider,
  paymentAt,
  paymentCabbage,
  balance,
  balanceL1,
  balanceL2,
  setAndStoreAccount,
  setLoadingMessage,
  setBalance,
  ogHolderProof,
  impersonating,
  localDatabase,
}: Context & Actions & { localDatabase: LocalDatabase }) {
  const clients = useMemo(() => {
    const context: Context = {
      account,
      link,
      client,
      connector,
      provider,
      paymentAt,
      paymentCabbage,
      balance,
      balanceL1,
      balanceL2,
      ogHolderProof,
      impersonating,
    };
    const actions: Actions = {
      setAndStoreAccount,
      setLoadingMessage,
      setBalance,
    };

    const ethereumClient = new EthereumClient(context, actions);
    const imxClient = new ImxClient(context, actions);
    const pawWarzApiClient = new PawWarzApiClient(ethereumClient);
    const priceClient = new PriceClient(context, actions);
    const assetClient = new AssetClient(localDatabase, pawWarzApiClient);
    const eventClient = new EventClient(localDatabase, pawWarzApiClient);
    const tokensClient = new TokensClient(localDatabase, imxClient);
    const rewardClient = new RewardClient(localDatabase, pawWarzApiClient);
    const decksClient = new DecksClient(localDatabase, pawWarzApiClient);
    const farmingClient = new FarmingClient(context, actions, localDatabase, ethereumClient, pawWarzApiClient);
    const paymentClient = new PaymentClient(context, actions, localDatabase, ethereumClient, imxClient, farmingClient);
    const assetActionsClient = new AssetActionsClient(
      context,
      actions,
      localDatabase,
      imxClient,
      priceClient,
      paymentClient,
      pawWarzApiClient,
      farmingClient
    );

    return {
      context,
      actions,

      ethereumClient,
      imxClient,
      pawWarzApiClient,
      assetClient,
      assetActionsClient,
      priceClient,
      eventClient,
      tokensClient,
      rewardClient,
      decksClient,
      farmingClient,
    };
  }, []);

  useEffect(() => {
    clients.context.account = account;
    clients.context.link = link;
    clients.context.client = client;
    clients.context.connector = connector;
    clients.context.provider = provider;
    clients.context.paymentAt = paymentAt;
    clients.context.paymentCabbage = paymentCabbage;
    clients.context.balance = balance;
    clients.context.balanceL1 = balanceL1;
    clients.context.balanceL2 = balanceL2;
    clients.context.ogHolderProof = ogHolderProof;
    clients.context.impersonating = impersonating;

    clients.actions.setAndStoreAccount = setAndStoreAccount;
    clients.actions.setLoadingMessage = setLoadingMessage;
    clients.actions.setBalance = setBalance;
  }, [
    account,
    link,
    client,
    connector,
    provider,
    localDatabase,
    paymentAt,
    paymentCabbage,
    balance,
    balanceL1,
    balanceL2,
    setAndStoreAccount,
    setLoadingMessage,
    setBalance,
    ogHolderProof,
  ]);

  return clients;
}
