import { packs } from "./config";
import * as uuid from "uuid";
import { toast } from "react-toastify";
import moment, { Moment } from "moment";
import { DeckToken, PaymentConfig, PaymentTxStatus, PaymentTxs } from "../clients/types";
import { captureException } from "@sentry/browser";

export const handleError = (error: any) => {
  console.error(error);

  if (error instanceof Error) {
    captureException(error);
  } else {
    captureException(new Error(error ? error.toString() : "Unknown error"));
  }

  let message =
    error &&
    (error?.response?.data?.message ||
      error?.data?.message ||
      error?.error?.message ||
      error?.reason ||
      error?.message ||
      error.toString());
  if (message && message.startsWith("execution reverted: ")) {
    message = message.replace("execution reverted: ", "");
  }
  toast.error(message || "Unknown error");
};

export const getRateLimited = async <T>(
  callable: (params: any) => Promise<{ result: Array<T>; cursor: string | undefined }>,
  params: any
): Promise<{ result: Array<T>; cursor: string | undefined }> => {
  try {
    return await callable(params);
  } catch (error) {
    if (error?.response?.status === 429) {
      await sleep(10000 + Math.random() * 20000);
      return await getRateLimited(callable, params);
    } else {
      throw error;
    }
  }
};

export const getUsingCursor = async <T>(
  callable: (params: any) => Promise<{ result: Array<T>; cursor: string | undefined }>,
  params: any
): Promise<Array<T>> => {
  const fullResult: Array<T> = [];
  let cursor: string | undefined = "yes";
  let res = await getRateLimited<T>(callable, params);
  while (cursor) {
    cursor = res.cursor;
    res.result.forEach((item) => fullResult.push(item));
    res = await getRateLimited<T>(callable, {
      ...params,
      cursor: cursor,
    });
  }
  return fullResult;
};

export const sleep = (milliseconds: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const upperFirst = (str: string | undefined): string | undefined =>
  str ? str.charAt(0).toUpperCase() + str.slice(1) : undefined;

export const numberWithCommas = (num: number): string => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const countMetadata = (assets, metadataSchemas) => {
  const metadataCounts = Object.fromEntries(metadataSchemas.map((schema) => [schema.name, {}]));
  assets &&
    assets.forEach((asset) => {
      const assetVariation = variationFull(asset.variation);
      metadataSchemas.forEach((schema) => {
        let values;
        if (schema.isVariation) {
          values = [assetVariation[schema.field]];
        } else {
          if (schema.field) {
            if (schema.listedTrait) {
              values = asset[schema.field].split(",");
            } else {
              values = [asset[schema.field]];
            }
          }
        }
        values = values.map(upperFirst).map((a: string) => (a === "Og-card" ? "OG Card" : a));
        values.forEach((value) => {
          metadataCounts[schema.name][value] = (metadataCounts[schema.name][value] || 0) + (asset.amount || 1);
        });
      });
    });
  return metadataCounts;
};

export type MetadataSchema = {
  name: string;
  field: string;
  isVariation: boolean;
  values: Array<string> | undefined;
  listedTrait: boolean;
  display: "check" | "boolean" | "range";
};

export const filterUsingMetadataFilter = (
  metadataSchemas: Array<MetadataSchema>,
  metadataFilter: Record<string, Record<string, boolean>>,
  search?: string
) => {
  const filter: Array<MetadataSchema> = [];

  if (metadataFilter) {
    metadataSchemas.forEach((schema) => {
      const selected: Array<string> = [];
      if (metadataFilter[schema.name]) {
        Object.entries(metadataFilter[schema.name]).forEach(([traitValue, s]) => {
          if (!traitValue.startsWith("~") && s) {
            selected.push(traitValue);
          }
        });
      }
      if (selected.length > 0) {
        filter.push({
          ...schema,
          values: selected,
        });
      }
    });
  }

  return (asset: any) => {
    const assetVariation = variationFull(asset.variation);
    let passed = filter.every((schema) => {
      let values: Array<string>;
      if (schema.display === "boolean") {
        values = [asset[schema.field] === "TRUE" || asset[schema.field] === true ? "true" : "false"];
      } else {
        if (schema.isVariation) {
          values = [assetVariation && assetVariation[schema.field]];
        } else {
          if (schema.listedTrait) {
            values = asset[schema.field].split(",");
          } else {
            values = [asset[schema.field]];
          }
        }
        values = values.map(upperFirst) as Array<string>;
        values = values.map((a: string) => (a === "Og-card" ? "OG Card" : a));
      }
      return !schema.values || schema.values.some((value) => values.includes(value));
    });

    if (search && search.length > 2 && passed) {
      passed = JSON.stringify(asset).toLowerCase().includes(search.toLowerCase());
    }

    return passed;
  };
};

const sessionSeed = Date.now();
export const deterministicChooseRandom = <T>(arr: Array<T>, num: number = 1): Array<T> => {
  let seed = sessionSeed;
  const res: Array<T> = [];
  for (let i = 0; i < num; ) {
    const random = Math.floor(Math.abs(Math.sin(seed++ * 31.2179)) * (seed % 1000)) % arr.length;
    if (res.indexOf(arr[random]) !== -1) {
      continue;
    }
    res.push(arr[random]);
    i++;
  }
  return res;
};

const guidNamespace = "6e3dc2f9-12eb-4d69-bb71-39e6de2a24a1";
export const guidFrom = (obj: any): string => {
  return uuid.v5(JSON.stringify(obj), guidNamespace);
};

export const variationFull = (variation) => {
  return {
    ...variation,
    frame: variation?.frame || "iron",
  };
};

export const variationId = (variation) => {
  if (variation && Object.keys(variation).length > 0) {
    return "_" + guidFrom(variation).slice(0, 6);
  }
  return "";
};

export const extendAssets = (assets, assetsFile) => {
  return (
    assets &&
    assetsFile &&
    assets
      .map((asset) => {
        const baseAsset = assetsFile.find((c) => c.fields.id === asset.typeId);
        return baseAsset
          ? {
              ...asset,
              ...baseAsset,
            }
          : null;
      })
      .filter((a) => !!a)
  );
};

export const reverseExtendAssets = (assets, assetsFile, type) => {
  return (
    assets &&
    assetsFile &&
    assetsFile.map((baseAsset) => {
      const asset = assets.find((asset) => asset.typeId === baseAsset.fields.id);
      return asset
        ? {
            ...baseAsset,
            ...asset,
          }
        : {
            type: type,
            typeId: baseAsset.fields.id,
            variation: undefined,
            tokenIds: [],
            amount: 0,
            ...baseAsset,
          };
    })
  );
};

export const extendPacks = (assets) => {
  return assets.map((asset) => {
    const basePack = packs.find((p) => p.typeId === asset.typeId);
    return {
      ...asset,
      ...basePack,
    };
  });
};

export const approximateBlockToTimestamp = (block: number): Moment => {
  const anchorBlock = 16449830;
  const anchorTimestamp = 1674230000;
  const blockTime = 12;
  return moment.unix((block - anchorBlock) * blockTime + anchorTimestamp);
};

export const validateDeck = (
  tokens: Array<DeckToken>,
  allAssets: Array<any>
): { title: string; message: string } | undefined => {
  const totalAmount = tokens.reduce((acc, token) => acc + token.amount, 0);

  if (totalAmount > 40) {
    return {
      title: "You have reached a deck maximum",
      message: "You may only have 40 cards in a deck.",
    };
  }

  let matched = 0;
  for (let token of tokens) {
    if (token.amount > 2) {
      return {
        title: "You have reached a deck maximum",
        message: "You may only have 2 copies of a card in a deck.",
      };
    }

    const asset = allAssets.find((a) => a.type === token.type && a.typeId === token.typeId);
    if (!asset) {
      return {
        title: "You have an unknown card in your deck",
        message: "Please remove it before proceeding.",
      };
    }

    if (asset.matched === "TRUE") {
      matched += token.amount;
    }

    if (asset.rarity === "Mythical") {
      if (token.amount > 1) {
        return {
          title: "You have reached a deck maximum",
          message: "You may only have 1 copy of an mythical card in a deck.",
        };
      }
    }
  }

  if (matched > 15) {
    return {
      title: "You have reached a deck maximum",
      message:
        "You may only have 15 cards with the ‘Matched’ trait. You must remove a ‘Matched’ card to add this card to your deck.",
    };
  }
};

export const newPaymentTxs = (payment: PaymentConfig): PaymentTxs => {
  const txs: PaymentTxs = {};
  if (payment.eth) {
    txs.eth = {
      tx: undefined,
      status: "new",
    };
  }
  if (payment.cabbage) {
    txs.cabbage = [
      {
        tx: undefined,
        status: "new",
      },
    ];
  }
  if (payment.assets) {
    txs.assets = new Array(payment.assets.length).fill({
      tx: undefined,
      status: "new",
    });
  }
  if (payment.fuseFarms) {
    txs.fuseFarms = {
      tx: undefined,
      status: "new",
    };
  }
  if (payment.onyxShards) {
    txs.onyxShards = {
      tx: undefined,
      status: "new",
    };
  }
  if (payment.goldNuggets) {
    txs.goldNuggets = {
      tx: undefined,
      status: "new",
    };
  }
  if (payment.uncutDiamonds) {
    txs.uncutDiamonds = {
      tx: undefined,
      status: "new",
    };
  }
  return txs;
};

export const gatherPaymentTxs = (paymentTxs: PaymentTxs): Array<string> => {
  const txs: Array<string> = [];

  if (paymentTxs.eth) txs.push(paymentTxs.eth.tx!);
  if (paymentTxs.cabbage) {
    paymentTxs.cabbage.forEach((paymentTx) => {
      txs.push(paymentTx.tx!);
    });
  }
  if (paymentTxs.assets) {
    paymentTxs.assets.forEach((paymentTx) => {
      txs.push(paymentTx.tx!);
    });
  }
  if (paymentTxs.fuseFarms) txs.push(paymentTxs.fuseFarms.tx!);
  if (paymentTxs.onyxShards) txs.push(paymentTxs.onyxShards.tx!);
  if (paymentTxs.goldNuggets) txs.push(paymentTxs.goldNuggets.tx!);
  if (paymentTxs.uncutDiamonds) txs.push(paymentTxs.uncutDiamonds.tx!);

  return txs;
};

export const gatherPaymentStatuses = (paymentTxs: PaymentTxs): Array<PaymentTxStatus> => {
  const statuses: Array<PaymentTxStatus> = [];

  if (paymentTxs.eth) statuses.push(paymentTxs.eth.status);
  if (paymentTxs.cabbage) {
    paymentTxs.cabbage.forEach((paymentTx) => {
      statuses.push(paymentTx.status);
    });
  }
  if (paymentTxs.assets) {
    paymentTxs.assets.forEach((paymentTx) => {
      statuses.push(paymentTx.status);
    });
  }
  if (paymentTxs.fuseFarms) statuses.push(paymentTxs.fuseFarms.status);
  if (paymentTxs.onyxShards) statuses.push(paymentTxs.onyxShards.status);
  if (paymentTxs.goldNuggets) statuses.push(paymentTxs.goldNuggets.status);
  if (paymentTxs.uncutDiamonds) statuses.push(paymentTxs.uncutDiamonds.status);

  return statuses;
};
