import LocalDatabase from "./LocalDatabase";
import PawWarzApiClient from "./PawWarzApiClient";
import { Deck } from "./types";

const DECKS_REFRESH_INTERVAL = 1000 * 60 * 60 * 4; // 4 hours

class DecksClient {
  localDatabase: LocalDatabase;
  pawWarzApiClient: PawWarzApiClient;

  constructor(localDatabase: LocalDatabase, pawWarzApiClient: PawWarzApiClient) {
    this.localDatabase = localDatabase;
    this.pawWarzApiClient = pawWarzApiClient;
  }

  async refreshDecks(account: string): Promise<void> {
    const version = (await this.localDatabase.getDecksVersion(account)) || 0;
    if (Date.now() > version + DECKS_REFRESH_INTERVAL) {
      const decks = await this.pawWarzApiClient.getPlayerDecks(account);
      await this.localDatabase.refreshDecks(decks, account);
    }
  }

  async saveDeck(account: string, deck: Deck): Promise<void> {
    await this.localDatabase.saveDeck(deck, account);
  }

  async getDecks(account: string): Promise<Array<Deck>> {
    return await this.localDatabase.getDecks(account);
  }
}

export default DecksClient;
