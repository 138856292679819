import LocalDatabase from "./LocalDatabase";
import ImxClient from "./ImxClient";

const TOKENS_REFRESH_INTERVAL = 1000 * 60 * 60 * 24 * 7; // one week

class TokensClient {
  localDatabase: LocalDatabase;
  imxClient: ImxClient;

  constructor(localDatabase: LocalDatabase, imxClient: ImxClient) {
    this.localDatabase = localDatabase;
    this.imxClient = imxClient;
  }

  async refreshTokens() {
    const version = (await this.localDatabase.getTokensVersion()) || 0;
    if (Date.now() > version + TOKENS_REFRESH_INTERVAL) {
      const tokens = await this.imxClient.getTokens();
      await this.localDatabase.refreshTokens(tokens);
    }
  }

  async getTokensMapping() {
    const tokens = await this.localDatabase.getTokens();
    return Object.fromEntries(tokens.map((t) => [t.token_address, t]));
  }
}

export default TokensClient;
