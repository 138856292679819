import { utils } from "ethers";

export const network = process.env.GATSBY_NETWORK! as "localhost" | "sepolia" | "mainnet";
export const collection = process.env.GATSBY_COLLECTION!;

export const smartContracts = {
  originalBunnies: process.env.GATSBY_ORIGINAL_BUNNIES_CONTRACT!,
  farm: process.env.GATSBY_FARMING_CONTRACT_FARM!,
  farmMinter: process.env.GATSBY_FARMING_CONTRACT_FARM_MINTER!,
  farmFuser: process.env.GATSBY_FARMING_CONTRACT_FARM_FUSER!,
};

const networkConfigs = {
  localhost: {
    chain_id: 11155111,
    paw_warz_api: "http://localhost:8000/api",
    imx_link_url: "https://link.sandbox.x.immutable.com",
    imx_env_url: "https://api.sandbox.x.immutable.com/v1",
    imx_stark_contract_address: "0x2d5C349fD8464DA06a3f90b4B0E9195F3d1b7F98",
    imx_registration_address: "0xDbA6129C02E69405622fAdc3d5A7f8d23eac3b97",
    imx_sales_wallet: "0x99613F187fC3916b1Bd6FaA2267b0ee0b3447D82",
    bucket: "test-badbunnies-xyz.appspot.com",
    alchemmy_url: "https://eth-sepolia.g.alchemy.com/v2/Qm0Smm9lQwBhzQx5kxhxIB2OmVgLEF9F",
    game_path: `http://localhost:9000`,
  },
  sepolia: {
    chain_id: 11155111,
    paw_warz_api: "https://test-badbunnies-xyz.web.app/api",
    imx_link_url: "https://link.sandbox.x.immutable.com",
    imx_env_url: "https://api.sandbox.x.immutable.com/v1",
    imx_stark_contract_address: "0x2d5C349fD8464DA06a3f90b4B0E9195F3d1b7F98",
    imx_registration_address: "0xDbA6129C02E69405622fAdc3d5A7f8d23eac3b97",
    imx_sales_wallet: "0x99613F187fC3916b1Bd6FaA2267b0ee0b3447D82",
    bucket: "test-badbunnies-xyz.appspot.com",
    alchemmy_url: "https://eth-sepolia.g.alchemy.com/v2/Qm0Smm9lQwBhzQx5kxhxIB2OmVgLEF9F",
    game_path: `https://storage.googleapis.com/test-badbunnies-xyz.appspot.com/game`,
  },
  mainnet: {
    chain_id: 1,
    paw_warz_api: "https://badbunnies.xyz/api",
    imx_link_url: "https://link.x.immutable.com",
    imx_env_url: "https://api.x.immutable.com/v1",
    imx_stark_contract_address: "0x5fdcca53617f4d2b9134b29090c87d01058e27e9",
    imx_registration_address: "0xb28816338bcc7eb4dc1e0c09341076db0b97f92f",
    imx_sales_wallet: "0xF7ae68614D401D1a29028cB0d752c128be9C9bb0",
    bucket: "badbunnies-xyz.appspot.com",
    alchemmy_url: "https://eth-mainnet.g.alchemy.com/v2/CVkzxiEwTvz55ai6NGOr94pUJs5B07Ar",
    game_path: `https://storage.googleapis.com/badbunnies-xyz.appspot.com/game`,
  },
};
export const networkConfig = networkConfigs[network];

export const packs = [
  {
    id: "rare",
    typeId: 1,
    name: "Rare",
    description: "1 Rare card (or better), 4 Common cards (or better)",
    price: utils.parseEther("0.007"),
    exampleCards: {
      rare: 1,
      common: 4,
    },
    exampleSpells: {
      common: 1,
    },
  },
  {
    id: "epic",
    typeId: 2,
    name: "Epic",
    description: "2 Epic cards (or better), 3 Common cards (or better)",
    price: utils.parseEther("0.017"),
    exampleCards: {
      epic: 1,
      rare: 1,
      common: 3,
    },
    exampleSpells: {
      rare: 1,
    },
  },
  {
    id: "legendary",
    typeId: 3,
    name: "Legendary",
    description: "1 Legendary card, 1 Rare card (or better), 3 Common cards (or better)",
    price: utils.parseEther("0.035"),
    exampleCards: {
      legendary: 1,
      epic: 1,
      rare: 1,
      common: 1,
    },
    exampleSpells: {
      epic: 1,
    },
  },
  {
    id: "shiny",
    typeId: 4,
    name: "Shiny",
    description:
      "1 Onyx, Gold or Diamond Legendary card, 1 Rare card (or better), 1 Onyx, Gold or Diamond Legendary card (or better), 2 Common cards (or better)",
    price: utils.parseEther("0.14"),
    exampleCards: {
      legendary: 1,
      epic: 1,
      rare: 1,
      common: 2,
    },
    exampleSpells: {},
  },
  {
    id: "halloween",
    typeId: 5,
    name: "Halloween",
    description: "A pack containing set of limited Halloween 2022 cards",
    price: utils.parseEther("0.14"),
    exampleAssetIdentifiers: [
      { type: "card", typeId: 219 },
      { type: "card", typeId: 220 },
      { type: "card", typeId: 221 },
      { type: "card", typeId: 222 },
      { type: "spell", typeId: 15 },
    ],
  },
  {
    id: "halloween-2",
    typeId: 6,
    name: "Halloween 2",
    description: "A pack containing set of limited Halloween 2023 cards",
    price: utils.parseEther("0.14"),
    exampleAssetIdentifiers: [
      { type: "card", typeId: 227 },
      { type: "card", typeId: 228 },
      { type: "card", typeId: 229 },
      { type: "card", typeId: 230 },
      { type: "card", typeId: 231 },
    ],
  },
];

export const buyAmounts = [1, 6, 18, 50, 100];

export const battlepass = [
  {
    id: "choppers",
    name: "Choppers battlepass",
    price: utils.parseEther("0.075"),
    bossId: 1,
    reward: {},
  },
  {
    id: "brawlers",
    name: "Brawlers battlepass",
    price: utils.parseEther("0.075"),
    bossId: 2,
    rewards: {},
  },
  {
    id: "diggers",
    name: "Diggers battlepass",
    price: utils.parseEther("0.075"),
    bossId: 3,
    rewards: {},
  },
  {
    id: "player",
    name: "Free battlepass",
    price: undefined,
    bossId: undefined,
    rewards: {
      400: [
        {
          type: "card",
          typeId: 7,
          amount: 1,
        },
      ],
      8000: [
        {
          type: "pack",
          typeId: 1,
          amount: 4,
        },
        {
          type: "cabbage",
          typeId: 1,
          amount: 2,
        },
      ],
      1600: [
        {
          type: "boss",
          typeId: 2,
          amount: 1,
        },
      ],
      3000: [
        {
          type: "pack",
          typeId: 2,
          amount: 1,
        },
      ],
      5000: [
        {
          type: "pack",
          typeId: 2,
          amount: 1,
        },
      ],
      10000: [
        {
          type: "pack",
          typeId: 2,
          amount: 1,
        },
      ],
      14000: [
        {
          type: "pack",
          typeId: 4,
          amount: 1,
        },
      ],
    },
  },
];

export const cabbagePackSizes = [50, 100, 500, 1000, 5000];

export const fuse = {
  card: {
    price: {
      common: utils.parseEther("0.003"),
      rare: utils.parseEther("0.006"),
      epic: utils.parseEther("0.01"),
      legendary: utils.parseEther("0.015"),
      mythical: utils.parseEther("0.015"),
    },
    cabbage: {
      common: 50,
      rare: 100,
      epic: 200,
      legendary: 400,
      mythical: 400,
    },
    farmingLoot: {
      common: 1,
      rare: 2,
      epic: 4,
      legendary: 8,
      mythical: 16,
    },
    amount: {
      iron: 2,
      onyx: 4,
      gold: 4,
    },
  },
  spell: {
    price: {
      common: utils.parseEther("0.003"),
      rare: utils.parseEther("0.006"),
      epic: utils.parseEther("0.01"),
      legendary: utils.parseEther("0.015"),
      mythical: utils.parseEther("0.015"),
    },
    cabbage: {
      common: 50,
      rare: 100,
      epic: 200,
      legendary: 400,
      mythical: 400,
    },
    farmingLoot: {
      common: 1,
      rare: 2,
      epic: 4,
      legendary: 8,
      mythical: 16,
    },
    amount: {
      iron: 2,
      onyx: 4,
      gold: 4,
    },
  },
  farm: {
    rare: {
      iron: {
        price: utils.parseEther("0.01"),
        cabbage: 500,
        farmingLoot: 8,
      },
      onyx: {
        price: utils.parseEther("0.02"),
        cabbage: 1000,
        farmingLoot: 8,
      },
      gold: {
        price: utils.parseEther("0.03"),
        cabbage: 2000,
        farmingLoot: 8,
      },
    },
    epic: {
      iron: {
        price: utils.parseEther("0.025"),
        cabbage: 1000,
        farmingLoot: 16,
      },
      onyx: {
        price: utils.parseEther("0.04"),
        cabbage: 1500,
        farmingLoot: 16,
      },
      gold: {
        price: utils.parseEther("0.06"),
        cabbage: 2500,
        farmingLoot: 16,
      },
    },
    legendary: {
      iron: {
        price: utils.parseEther("0.05"),
        cabbage: 2000,
        farmingLoot: 24,
      },
      onyx: {
        price: utils.parseEther("0.075"),
        cabbage: 3000,
        farmingLoot: 24,
      },
      gold: {
        price: utils.parseEther("0.1"),
        cabbage: 5000,
        farmingLoot: 24,
      },
    },
    amount: {
      iron: 2,
      onyx: 2,
      gold: 2,
    },
  },
};

export const hijackedCabbageRate = 0.25;
export const hijackedLockboxCabbageRate = 0.15;

export const marketplace = {
  feePercentage: 1,
  feeRecipient: "0xF7ae68614D401D1a29028cB0d752c128be9C9bb0",
};

export const adminWallets = [
  "0x99613F187fC3916b1Bd6FaA2267b0ee0b3447D82",
  "0xF7ae68614D401D1a29028cB0d752c128be9C9bb0",
  "0x3Ba7E19cFA13d3Cf0bc68b14289CD5CBF34633e1",
];
