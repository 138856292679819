import React from "react";
import WalletInput from "../../WalletInput/WalletInput";
import { ActionButton, StepTitle, Progress, Img100, Error } from "../ImxLogin.elements";
import { useImx } from "../../../contexts/ImxContext";

const StepRegisterImmutableX = () => {
  const { actionHandler, imxClient, setLoadingMessage } = useImx();

  const unlockImmutableX = async () => {
    setLoadingMessage("Please, connect your wallet");
    await imxClient.connect();
  };

  return (
    <>
      <StepTitle>STEP 02 - AUTHORISE IMMUTABLE X</StepTitle>
      <Img100 src="/assets/images/immutable-x-unlock.png" alt="Immutable X connect process" />
      <Progress>
        <div className="full"></div>
        <div className="a-bit"></div>
      </Progress>
      <WalletInput />
      <div className="not-on-mobile">
        <ActionButton onClick={() => actionHandler(unlockImmutableX)}>Unlock ImmutableX</ActionButton>
      </div>
      <div className="on-mobile">
        <Error>Immutable X not supported on mobile, please use a desktop.</Error>
      </div>
    </>
  );
};

export default StepRegisterImmutableX;
