import { networkConfig, smartContracts } from "../utils/config";
import { sleep } from "../utils/utils";
import { BigNumber, Contract, utils } from "ethers";
import { Provider } from "@ethersproject/abstract-provider";
import { Signer } from "@ethersproject/abstract-signer";
import { Context, Actions } from "./types";
import { metaMaskConnector, urlConnector } from "../utils/connectors";

import OriginalBunnies from "./abi/OriginalBunnies.json";
import Farm from "./abi/Farm.json";
import FarmMinter from "./abi/FarmMinter.json";
import FarmFuser from "./abi/FarmFuser.json";

class EthereumClient {
  context: Context;
  actions: Actions;

  originalBunnies: Contract;
  farm: Contract;
  farmMinter: Contract;
  farmFuser: Contract;

  constructor(context: Context, actions: Actions) {
    this.context = context;
    this.actions = actions;

    this.originalBunnies = new Contract(smartContracts.originalBunnies, OriginalBunnies.abi);
    this.farm = new Contract(smartContracts.farm, Farm.abi);
    this.farmMinter = new Contract(smartContracts.farmMinter, FarmMinter.abi);
    this.farmFuser = new Contract(smartContracts.farmFuser, FarmFuser.abi);
  }

  getBestProvider(): Signer | Provider {
    let provider: Signer | Provider;
    if (!this.context.provider) {
      throw new Error("No provider initialized");
    }
    if (this.context.connector === urlConnector) {
      provider = this.context.provider;
    } else {
      const signer = this.context.provider.getSigner();
      if (signer) {
        provider = signer;
      } else {
        provider = this.context.provider;
      }
    }
    return provider;
  }

  getSigner() {
    if (!this.context.provider) {
      throw new Error("No provider initialized");
    }
    if (this.context.impersonating) {
      throw new Error("Cannot sign transaction while impersonating");
    }
    return this.context.provider.getSigner();
  }

  getOriginalBunniesContract(): Contract {
    return this.originalBunnies.connect(this.getBestProvider());
  }

  getFarmContract(): Contract {
    return this.farm.connect(this.getBestProvider());
  }

  getFarmMinterContract(): Contract {
    return this.farmMinter.connect(this.getBestProvider());
  }

  getFarmFuserContract(): Contract {
    return this.farmFuser.connect(this.getBestProvider());
  }

  async signData(object: any): Promise<string> {
    await this.connect();
    return await this.getSigner().signMessage(JSON.stringify(object));
  }

  async getBalance(): Promise<BigNumber> {
    return await this.getBalanceOf(this.context.account);
  }

  async validateHasBalance(amount: BigNumber): Promise<void> {
    const balance = await this.getBalance();
    if (balance.lt(amount)) {
      throw new Error("Not enough balance");
    }
  }

  async getBalanceOf(address: string): Promise<BigNumber> {
    if (!this.context.provider) {
      throw new Error("No provider initialized");
    }
    return await this.context.provider.getBalance(address);
  }

  async connect(): Promise<void> {
    await metaMaskConnector.activate();
    while (true) {
      if (this.context.connector === metaMaskConnector && this.context.provider) {
        try {
          this.context.provider.getSigner().getAddress();
        } catch (e) {
          await sleep(250);
          continue;
        }
        break;
      }
      await sleep(250);
    }
  }

  async payFor(price: BigNumber): Promise<string> {
    const transferCost = BigNumber.from(21000n).mul(await this.getBestProvider().getGasPrice());
    await this.validateHasBalance(price.add(transferCost));
    const tx = await this.getSigner().sendTransaction({
      to: networkConfig.imx_sales_wallet,
      value: price,
    });
    return tx.hash;
  }

  async burnFarm(farmId: number): Promise<string> {
    const farm = this.getFarmContract();
    await this.validateHasBalance(await farm.estimateGas.burn(farmId));
    const tx = await this.getFarmContract().burn(farmId);
    return tx.hash;
  }

  async fuseFarms(farmIds: Array<number>, price: BigNumber): Promise<string> {
    const farmFuser = this.getFarmFuserContract();
    const transactionCost = await farmFuser.estimateGas.fuse(farmIds, {
      value: price,
    });
    await this.validateHasBalance(price.add(transactionCost));
    const tx = await farmFuser.fuse(farmIds, { value: price });
    return tx.hash;
  }

  async waitForTransaction(tx: string): Promise<void> {
    if (!this.context.provider) {
      throw new Error("No provider initialized");
    }
    const receipt = await this.context.provider.waitForTransaction(tx);
    if (receipt.status == 0) {
      throw new Error("Transaction failed");
    }
  }

  addressName(account: string): string {
    if (utils.isAddress(account)) {
      const fullAddress = utils.getAddress(account);
      const prefix = fullAddress.slice(0, 6);
      const suffix = fullAddress.slice(38, 42);
      return `${prefix}...${suffix}`;
    } else {
      return account;
    }
  }

  async addressNameResolve(account: string): Promise<string> {
    if (this.context.provider) {
      const reverseName = await this.context.provider.lookupAddress(account);
      if (reverseName) {
        return reverseName;
      }
    }
    return this.addressName(account);
  }
}

export default EthereumClient;
