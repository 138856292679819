import moment from "moment";
import LocalDatabase from "./LocalDatabase";
import PawWarzApiClient from "./PawWarzApiClient";
import { CurrentEvents } from "./types";

const EVENT_REFRESH_INTERVAL = 1000 * 60 * 60 * 24; // one day
const FORCE_REFRESH_TIMESTAMP = 1669122600000;

class EventClient {
  localDatabase: LocalDatabase;
  pawWarzApiClient: PawWarzApiClient;

  constructor(localDatabase: LocalDatabase, pawWarzApiClient: PawWarzApiClient) {
    this.localDatabase = localDatabase;
    this.pawWarzApiClient = pawWarzApiClient;
  }

  async refreshEvents(): Promise<void> {
    const version = (await this.localDatabase.getEventsVersion()) || 0;
    if (version < FORCE_REFRESH_TIMESTAMP || Date.now() > version + EVENT_REFRESH_INTERVAL) {
      const events = await this.pawWarzApiClient.getCloseEvents();
      await this.localDatabase.refreshEvents(events);
    }
  }

  async getCurrentEvents(): Promise<CurrentEvents> {
    const now = moment();
    const closeEvents = await this.localDatabase.getEvents();
    const currentEvents: CurrentEvents = {
      sale: undefined,
      openPack: undefined,
      farmingEvent: undefined,
    };
    closeEvents.forEach((event) => {
      if (now.valueOf() >= moment(event.eventSince).valueOf() && now.valueOf() < moment(event.eventUntil).valueOf()) {
        if (event.type === "open-pack") {
          currentEvents["openPack"] = event;
        } else if (event.type === "farming-event") {
          currentEvents["farmingEvent"] = event;
        } else {
          currentEvents[event.type] = event;
        }
      }
    });
    return currentEvents;
  }
}

export default EventClient;
