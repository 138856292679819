import React, { useEffect } from "react";
import WalletInput from "../../WalletInput/WalletInput";
import { ActionButton, StepTitle, Progress } from "../ImxLogin.elements";
import { ethers } from "ethers";
import { useImx } from "../../../contexts/ImxContext";

const StepConnectEthereum = () => {
  const { account, actionHandler, link, nextStep, setLoadingMessage, ethereumClient } = useImx();

  const connectWallet = async () => {
    setLoadingMessage("Please, connect your wallet");
    await ethereumClient.connect();
  };

  return (
    <>
      <StepTitle>STEP 01 - CONNECT</StepTitle>
      <Progress>
        <div className="a-bit"></div>
        <div className="empty"></div>
      </Progress>
      <WalletInput />
      <ActionButton onClick={() => actionHandler(connectWallet)}>Connect wallet</ActionButton>
    </>
  );
};

export default StepConnectEthereum;
