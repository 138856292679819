import { initializeConnector } from "@web3-react/core";
import { MetaMask } from "@web3-react/metamask";
import { Url } from "@web3-react/url";
import { networkConfig } from "./config";
import { handleError } from "./utils";

export const [metaMaskConnector, metaMaskHooks] = initializeConnector<MetaMask>(
  (actions) =>
    new MetaMask({
      actions,
      onError: handleError,
    })
);

export const [urlConnector, urlHooks] = initializeConnector<Url>(
  (actions) =>
    new Url({
      actions,
      url: networkConfig.alchemmy_url,
    })
);

export const connectors = [
  [metaMaskConnector, metaMaskHooks],
  [urlConnector, urlHooks],
];
