import { guidFrom, sleep, newPaymentTxs, gatherPaymentTxs, gatherPaymentStatuses, handleError } from "../utils/utils";
import LocalDatabase from "./LocalDatabase";
import ImxClient from "./ImxClient";
import PriceClient from "./PriceClient";
import PaymentClient from "./PaymentClient";
import PawWarzApiClient from "./PawWarzApiClient";
import FarmingClient from "./FarmingClient";
import {
  Context,
  Actions,
  MintResult,
  MintEntry,
  MergedAsset,
  ExtendedAsset,
  PaymentConfig,
  UnifiedAsset,
  UnifiedAssetType,
  CurrentEvents,
} from "./types";
import { BigNumber, utils } from "ethers";
import { toast } from "react-toastify";

const fbq = (typeof window !== "undefined" && window.fbq) || (() => {});

const ETH_TO_USD = 1700;

const manuallyResolvedTxs = [
  "0x271d2447ff1c9e731e9f23d9b41b37cb87c17568ba7d42ae48b02a130dd11a8b",
  "0xcfeded73f16fe0d4b403dc6bf1ca1294aad37b91c216d13c38580da7aab1969c",
  "0x48c4ee57721b952c8b13fbae8e22393be19277b6ae89f65a0d8ffe86b18bb729",
  "0xaa1754633e927e8ed3180acd9a9d273f059a2358eda1f49797c50893609cc5ac",
];

class AssetActionsClient {
  context: Context;
  actions: Actions;
  localDatabase: LocalDatabase;
  imxClient: ImxClient;
  priceClient: PriceClient;
  paymentClient: PaymentClient;
  pawWarzApiClient: PawWarzApiClient;
  farmingClient: FarmingClient;

  constructor(
    context: Context,
    actions: Actions,
    localDatabase: LocalDatabase,
    imxClient: ImxClient,
    priceClient: PriceClient,
    paymentClient: PaymentClient,
    pawWarzApiClient: PawWarzApiClient,
    farmingClient: FarmingClient
  ) {
    this.context = context;
    this.actions = actions;
    this.localDatabase = localDatabase;
    this.imxClient = imxClient;
    this.priceClient = priceClient;
    this.paymentClient = paymentClient;
    this.pawWarzApiClient = pawWarzApiClient;
    this.farmingClient = farmingClient;
  }

  async updateAssets(mintResult: MintResult): Promise<void> {
    for (let mintedAsset of mintResult.assets) {
      await this.localDatabase.addAsset(
        {
          type: mintedAsset.type as UnifiedAssetType,
          typeId: mintedAsset.typeId,
          variation: mintedAsset.variation,
          tokenId: mintedAsset.tokenId,
        },
        this.context.account
      );
    }

    for (let lostAsset of mintResult.lostAssets) {
      await this.localDatabase.removeAsset(
        {
          type: lostAsset.type as UnifiedAssetType,
          typeId: lostAsset.typeId,
          variation: lostAsset.variation,
          tokenId: lostAsset.tokenId,
        },
        this.context.account
      );
    }
  }

  async finalizeMint(mint: MintEntry): Promise<MintResult> {
    let mintResult: MintResult;
    const txs = gatherPaymentTxs(mint.txs);

    if (mint["tx"] || mint["txs"]) {
      if (txs.some((tx: string) => manuallyResolvedTxs.includes(tx))) {
        await this.localDatabase.resolveMintJournalEntry(mint.id, this.context.account);
        return {
          status: "success",
          message: "Done manually",
          to: this.context.account,
          assets: [],
          lostAssets: [],
        };
      }
    }

    try {
      if (mint.type === "pack") {
        mintResult = await this.pawWarzApiClient.mintPack(txs[0], mint.pack, mint.amount, mint.ogHolderProof);
      } else if (mint.type === "battlepass") {
        mintResult = await this.pawWarzApiClient.mintBattlepass(txs[0], mint.battlepass);
      } else if (mint.type === "cabbage-pack") {
        mintResult = await this.pawWarzApiClient.cabbagePack(txs, mint.amount);
      } else if (mint.type === "cabbage-unpack") {
        mintResult = await this.pawWarzApiClient.cabbageUnpack(txs, mint.amount);
      } else if (mint.type === "open-pack") {
        mintResult = await this.pawWarzApiClient.mintOpenPack(txs[0]);
      } else if (mint.type === "fuse") {
        mintResult = await this.pawWarzApiClient.mintFuse(txs);
      } else if (mint.type === "farm-fuse") {
        mintResult = await this.pawWarzApiClient.farmFuse(txs);
      } else if (mint.type === "cabbage-shop") {
        mintResult = await this.pawWarzApiClient.cabbageShopBuy(mint.itemId, txs, mint.info);
      } else if (mint.type === "buy") {
        mintResult = await this.pawWarzApiClient.buy(mint.orderId);
      } else {
        throw new Error("Unknown mint type");
      }
      await this.localDatabase.resolveMintJournalEntry(mint.id, this.context.account);
      await this.updateAssets(mintResult);
      return mintResult;
    } catch (error) {
      if (error?.response?.data?.message.match(/Transaction.*? already minted/)) {
        await this.localDatabase.resolveMintJournalEntry(mint.id, this.context.account);
        return {
          status: "success",
          message: "Done already",
          to: this.context.account,
          assets: [],
          lostAssets: [],
        };
      } else {
        throw error;
      }
    }
  }

  async executeMint(
    mintConfig: MintEntry,
    mintOptions: {
      cabbageAssets?: Array<UnifiedAsset>;
      onTxsInitiated?: () => void;
      forceUnpackedCabbage?: boolean;
      forcePackedCabbage?: boolean;
    } = {}
  ) {
    const mint = await this.localDatabase.ensureMintJournalEntry(mintConfig, this.context.account);

    const initiatePaymentPromise = this.paymentClient.initiatePayment(mint, mintOptions);
    toast.promise(initiatePaymentPromise, {
      pending: "Please sign all payment transactions.",
      error: {
        render() {
          return "Payment failed! Please retry.";
        },
        autoClose: false,
      },
    });
    await initiatePaymentPromise;

    if (mintOptions.onTxsInitiated) {
      mintOptions.onTxsInitiated();
    }

    const concludePaymentPromise = this.paymentClient.concludePayment(mint);
    toast.promise(concludePaymentPromise, {
      pending: "Waiting for transaction confirmation...",
      error: {
        render() {
          return "Cannot confirm transaction, try again later.";
        },
        autoClose: false,
      },
    });
    await concludePaymentPromise;

    const finalizeMintPromise = this.finalizeMint(mint);
    toast.promise(finalizeMintPromise, {
      pending: "Finalizing mint",
      success: {
        render() {
          return "Done";
        },
        autoClose: 100,
      },
      error: "Mint failed!",
    });
    const mintResult = await finalizeMintPromise;

    return mintResult;
  }

  async finalizeMintJournal(account: string): Promise<void> {
    const mintConfigs = await this.localDatabase.getMintJournalEntries(account);

    for (let i = 0; i < mintConfigs.length; i++) {
      const mint = mintConfigs[i];

      const paymentStates = gatherPaymentStatuses(mint.txs);
      if (paymentStates.some((state) => state == "new" || state == "failed")) {
        // This mint needs to be manually retriggered by the user
        continue;
      }

      if (paymentStates.some((state) => state == "pending")) {
        await this.paymentClient.concludePayment(mint);
      }

      await this.finalizeMint(mint);
    }
  }

  trackPayment(id: string, amount: number, price: BigNumber) {
    setTimeout(() => {
      fbq("track", "Purchase", {
        value: ETH_TO_USD * parseFloat(utils.formatEther(price)),
        currency: "USD",
        content_type: "product",
        contents: [
          {
            id: id,
            quantity: amount,
          },
        ],
      });
    });
  }

  async mintPack(pack: string, amount: number, currentEvents: CurrentEvents): Promise<MintResult> {
    const price = this.priceClient.getPackMintPrice(pack, amount, currentEvents);
    const payment: PaymentConfig = {
      eth: price,
    };
    const mintConfig: MintEntry = {
      type: "pack",
      id: guidFrom({
        type: "pack",
        pack,
        amount,
      }),
      payment,
      txs: newPaymentTxs(payment),
      pack,
      amount,
      ogHolderProof: this.context.ogHolderProof,
    };

    const mintResult = await this.executeMint(mintConfig);

    this.trackPayment(`pack-${pack}`, amount, price);

    return mintResult;
  }

  async mintBattlepass(battlepass: string): Promise<MintResult> {
    const price = this.priceClient.getBattlepassMintPrice(battlepass);
    const payment: PaymentConfig = {
      eth: price,
    };
    const mintConfig: MintEntry = {
      type: "battlepass",
      id: guidFrom({
        type: "battlepass",
        battlepass,
      }),
      payment,
      txs: newPaymentTxs(payment),
      battlepass,
    };

    const mintResult = await this.executeMint(mintConfig);

    this.trackPayment(`battlepass-${battlepass}`, 1, price);

    return mintResult;
  }

  async openPack(packAsset: UnifiedAsset, onBurned: () => void): Promise<MintResult> {
    const payment: PaymentConfig = {
      assets: [
        {
          // removing any additional fields
          type: packAsset.type,
          typeId: packAsset.typeId,
          tokenId: packAsset.tokenId,
          variation: packAsset.variation,
        },
      ],
    };
    const mintConfig: MintEntry = {
      type: "open-pack",
      id: guidFrom({
        type: "open-pack",
        packAsset,
      }),
      payment,
      txs: newPaymentTxs(payment),
    };

    const mintResult = await this.executeMint(mintConfig, {
      onTxsInitiated: onBurned,
    });

    setTimeout(() => {
      fbq("trackCustom", "OpenPack", { pack: packAsset.typeId });
    });
    return mintResult;
  }

  async fuse(
    fuseAsset: ExtendedAsset,
    cabbageAssets: Array<UnifiedAsset>,
    fusePricing: {
      ethPrice?: BigNumber;
      cabbage?: number;
      farmingLoot?: {
        onyxShards?: number;
        goldNuggets?: number;
        uncutDiamonds?: number;
      };
      amount: number;
    },
    onBurned: () => void
  ): Promise<MintResult> {
    const fuseAssetUnrolled = fuseAsset.tokenIds.slice(0, fusePricing.amount).map((tokenId) => ({
      // removing any additional fields
      type: fuseAsset.type,
      typeId:
        fuseAsset.type == "farm"
          ? tokenId // recover typeId that got lost in loose asset retrieval on fuse UI
          : fuseAsset.typeId,
      tokenId,
      variation: fuseAsset.variation,
    }));
    if (fuseAssetUnrolled.length !== fusePricing.amount) {
      throw new Error("Not enough cards");
    }
    fuseAssetUnrolled.forEach((asset) => {
      if (asset.type == "farm" || asset.type == "original-bunny") {
        if (asset.typeId != asset.tokenId) {
          throw new Error("typeId and tokenId must be equal for farm and original-bunny assets");
        }
      }
    });

    const mintType = fuseAsset.type == "farm" ? "farm-fuse" : "fuse";
    const payment: PaymentConfig =
      fuseAsset.type == "farm"
        ? {
            fuseFarms: {
              farms: fuseAssetUnrolled,
              eth: fusePricing.ethPrice || BigNumber.from(0),
            },
            cabbage: fusePricing.cabbage,
            onyxShards: fusePricing?.farmingLoot?.onyxShards,
            goldNuggets: fusePricing?.farmingLoot?.goldNuggets,
            uncutDiamonds: fusePricing?.farmingLoot?.uncutDiamonds,
          }
        : {
            assets: fuseAssetUnrolled,
            eth: fusePricing.ethPrice,
            cabbage: fusePricing.cabbage,
            onyxShards: fusePricing?.farmingLoot?.onyxShards,
            goldNuggets: fusePricing?.farmingLoot?.goldNuggets,
            uncutDiamonds: fusePricing?.farmingLoot?.uncutDiamonds,
          };
    const mintConfig: MintEntry = {
      type: mintType,
      id: guidFrom({
        type: mintType,
        assets: payment.assets?.map((a) => ({
          type: a.type,
          typeId: a.typeId,
          // tokenId: value ignored
          variation: a.variation,
        })),
        eth: payment.eth,
        cabbage: payment.cabbage,
        onyxShards: payment.onyxShards,
        goldNuggets: payment.goldNuggets,
        uncutDiamonds: payment.uncutDiamonds,
      }),
      payment,
      txs: newPaymentTxs(payment),
    };

    const mintResult = await this.executeMint(mintConfig, {
      cabbageAssets: cabbageAssets,
      onTxsInitiated: onBurned,
    });

    if (fusePricing.ethPrice) {
      this.trackPayment(`fuse`, 1, fusePricing.ethPrice);
    }
    setTimeout(() => {
      fbq("trackCustom", "Fuse");
    });

    if (fuseAsset.type == "farm") {
      this.pawWarzApiClient.farmWasFused(mintResult.assets[0].tokenId).catch(handleError);
    }

    return mintResult;
  }

  async cabbagePack(amount: number): Promise<MintResult> {
    const payment: PaymentConfig = {
      cabbage: amount,
    };
    const mintConfig: MintEntry = {
      type: "cabbage-pack",
      id: guidFrom({
        type: "cabbage-pack",
        amount,
      }),
      payment,
      txs: newPaymentTxs(payment),
      amount,
    };

    const mintResult = await this.executeMint(mintConfig, {
      forceUnpackedCabbage: true,
    });

    await this.farmingClient.refreshFarmingPlayer(this.context.account);
    setTimeout(() => {
      fbq("trackCustom", "CabbagePack", { amount: amount });
    });

    return mintResult;
  }

  async cabbageUnpack(cabbageAssets: Array<UnifiedAsset>): Promise<MintResult> {
    const amount = cabbageAssets.reduce((acc, a) => acc + a.typeId * 50, 0);
    const payment: PaymentConfig = {
      cabbage: amount,
    };
    const mintConfig: MintEntry = {
      type: "cabbage-unpack",
      id: guidFrom({
        type: "cabbage-unpack",
        amount,
      }),
      payment,
      txs: newPaymentTxs(payment),
      amount,
    };

    const mintResult = await this.executeMint(mintConfig, {
      forcePackedCabbage: true,
      cabbageAssets,
    });

    await this.farmingClient.refreshFarmingPlayer(this.context.account);
    setTimeout(() => {
      fbq("trackCustom", "CabbageUnpack", { amount: amount });
    });

    return mintResult;
  }

  async claimReward(rewardId: number): Promise<MintResult> {
    this.actions.setLoadingMessage("Claiming...");
    const mintResult = await this.pawWarzApiClient.claimReward(rewardId);
    await this.updateAssets(mintResult);
    await this.localDatabase.storeRewardClaimed(rewardId, this.context.account);
    setTimeout(() => {
      fbq("trackCustom", "Reward");
    });
    return mintResult;
  }

  async redeem(code: string): Promise<MintResult> {
    this.actions.setLoadingMessage("Redeeming...");
    const mintResult = await this.pawWarzApiClient.redeem(code);
    await this.updateAssets(mintResult);
    setTimeout(() => {
      fbq("trackCustom", "Redeem");
    });
    return mintResult;
  }

  async claimBattlepassReward(battlepass: string, rewardAmount: number): Promise<MintResult> {
    this.actions.setLoadingMessage("Claiming...");
    const mintResult = await this.pawWarzApiClient.claimBattlepassReward(
      this.context.account,
      battlepass,
      rewardAmount
    );
    await this.updateAssets(mintResult);
    setTimeout(() => {
      fbq("trackCustom", "BattlepassReward");
    });
    return mintResult;
  }

  async buy(order: any): Promise<MintResult> {
    const mintConfig = {
      type: "buy",
      orderId: order.order_id,
    };

    const price = order.buy.data.quantity_with_fees as BigNumber;
    if (this.context.paymentAt === "eth") {
      this.actions.setLoadingMessage("Please, sign deposit transaction");
      await this.imxClient.deposit("", price); //TODO, other tokens
      this.actions.setLoadingMessage("Waiting for deposit to be processed. This may take few minutes.");
      await this.imxClient.waitForDepositProcessed(this.context.balanceL2);
    }

    this.actions.setLoadingMessage("Please, sign buy transaction");
    const result = await this.imxClient.buy(order.order_id);
    if (result.status !== "success") {
      throw new Error(result.message);
    }
    const mint: MintEntry = {
      id: guidFrom(mintConfig),
      type: "buy",
      payment: {},
      txs: {},
      orderId: order.order_id,
    };
    await this.localDatabase.ensureMintJournalEntry(mint, this.context.account);
    this.actions.setLoadingMessage("Waiting for transaction confirmation...");
    await sleep(1000); // is there a way to confirm trade?

    this.actions.setLoadingMessage("Finishing order...");
    return await this.finalizeMint(mint);
  }

  async cabbageShopBuy(
    itemId: string,
    price: number,
    cabbageAssets: Array<UnifiedAsset>,
    info?: Record<string, string>
  ): Promise<MintResult> {
    const payment: PaymentConfig = {
      cabbage: price,
    };
    const mintConfig: MintEntry = {
      type: "cabbage-shop",
      id: guidFrom({
        type: "cabbage-shop",
        itemId: itemId,
      }),
      payment,
      txs: newPaymentTxs(payment),
      itemId: itemId,
      info,
    };

    const mintResult = await this.executeMint(mintConfig, {
      cabbageAssets: cabbageAssets,
    });

    await this.farmingClient.refreshFarmingPlayer(this.context.account);

    return mintResult;
  }

  async customize(asset: MergedAsset, tokenId: number, customizeTraits: Record<string, string>): Promise<void> {
    this.actions.setLoadingMessage("Waiting for signature...");
    const customizeResult = await this.pawWarzApiClient.customize(tokenId, customizeTraits);

    await this.localDatabase.removeAsset(
      {
        ...asset,
        tokenId: tokenId,
      },
      this.context.account
    );
    await this.localDatabase.addAsset(
      {
        ...asset,
        variation: Object.assign({}, asset.variation, customizeTraits),
        tokenId: tokenId,
      },
      this.context.account
    );
  }
}

export default AssetActionsClient;
