import { BigNumber } from "ethers";
import moment from "moment";
import { packs, battlepass as battlepassConfigs, fuse } from "../utils/config";
import { variationFull } from "../utils/utils";
import { Context, Actions, ExtendedAsset, CardRarityType, FarmRarityType, FrameFuseType, CurrentEvents } from "./types";

class PriceClient {
  context: Context;
  actions: Actions;

  constructor(context: Context, actions: Actions) {
    this.context = context;
    this.actions = actions;
  }

  getPackMintPrice(pack: string, amount: number, currentEvents: CurrentEvents): BigNumber {
    const packConfig = packs.find((p) => p.id === pack);
    let price = packConfig ? packConfig.price.mul(amount) : BigNumber.from(0);
    if (currentEvents && currentEvents.sale) {
      const sale = currentEvents.sale;
      const now = moment();
      if (now.valueOf() > moment(sale.eventSince).valueOf() && now.valueOf() < moment(sale.eventUntil).valueOf()) {
        if (this.context.account && this.context.ogHolderProof) {
          price = price.mul(100 - sale.ogPercentage).div(100);
        } else {
          price = price.mul(100 - sale.percentage).div(100);
        }
      }
    }
    return price;
  }

  getBattlepassMintPrice(battlepass: string): BigNumber {
    const battlepassConfig = battlepassConfigs.find((p) => p.id === battlepass);
    return battlepassConfig?.price || BigNumber.from(0);
  }

  expandFuseLoot(frame: string, amount: number) {
    if (frame == "iron") {
      return {
        onyxShards: amount,
      };
    } else if (frame == "onyx") {
      return {
        goldNuggets: amount,
      };
    } else if (frame == "gold") {
      return {
        uncutDiamonds: amount,
      };
    } else {
      throw new Error(`Incorrect frame type ${frame}`);
    }
  }

  getFuseMintPricing(asset: ExtendedAsset): Array<{
    ethPrice?: BigNumber;
    cabbage?: number;
    farmingLoot?: {
      onyxShards?: number;
      goldNuggets?: number;
      uncutDiamonds?: number;
    };
    amount: number;
  }> {
    const assetVariation = variationFull(asset.variation);
    const frame = assetVariation.frame as FrameFuseType;
    if (asset.type == "card") {
      const rarity = asset.rarity.toLowerCase() as CardRarityType;

      // cards restricted to full fusing
      // if (asset.typeId == 225) {
      //   return [
      //     {
      //       ethPrice: fuse.card.price[rarity],
      //       cabbage: fuse.card.cabbage[rarity],
      //       amount: fuse.card.amount[frame],
      //     },
      //   ];
      // }

      return [
        {
          ethPrice: fuse.card.price[rarity],
          cabbage: fuse.card.cabbage[rarity],
          amount: fuse.card.amount[frame],
        },
        // {
        //   farmingLoot: this.expandFuseLoot(
        //     assetVariation.frame,
        //     fuse.card.farmingLoot[rarity],
        //   ),
        //   amount: fuse.card.amount[assetVariation.frame as FrameFuseType],
        // },
      ];
    } else if (asset.type == "spell") {
      const rarity = asset.rarity.toLowerCase() as CardRarityType;
      return [
        {
          ethPrice: fuse.spell.price[rarity],
          cabbage: fuse.spell.cabbage[rarity],
          amount: fuse.spell.amount[frame],
        },
        // {
        //   farmingLoot: this.expandFuseLoot(
        //     assetVariation.frame,
        //     fuse.spell.farmingLoot[rarity],
        //   ),
        //   amount: fuse.spell.amount[frame],
        // },
      ];
    } else if (asset.type == "farm") {
      const rarity = asset.rarity.toLowerCase() as FarmRarityType;
      return [
        {
          ethPrice: fuse.farm[rarity][frame].price,
          cabbage: fuse.farm[rarity][frame].cabbage,
          farmingLoot: this.expandFuseLoot(assetVariation.frame, fuse.farm[rarity][frame].farmingLoot),
          amount: fuse.farm.amount[frame],
        },
      ];
    } else {
      throw new Error("Invalid asset type");
    }
  }
}

export default PriceClient;
