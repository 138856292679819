import { useEffect, useState } from "react";
import { useImx } from "../contexts/ImxContext";
import { handleError } from "../utils/utils";

export default function useRewards() {
  const { account, localDatabase, rewardClient } = useImx();
  const [rewards, setRewards] = useState();

  useEffect(() => {
    const loadRewards = async () => {
      const rewards = await rewardClient.getRewards(account);
      setRewards(rewards);
    };

    const refresh = () => {
      loadRewards().catch(handleError);
    };

    refresh();
    localDatabase.onRewardsChange(refresh);
    return () => {
      localDatabase.offRewardsChange(refresh);
    };
  }, [account]);

  return rewards;
}
