import React, { createContext, useContext, useState, useEffect, useMemo, FunctionComponent, ReactNode } from "react";
import ImxLogin from "../components/ImxLogin/ImxLogin";
import Modal from "../components/Modal/Modal";
import { ImmutableXClient, Link } from "@imtbl/imx-sdk";
import { handleError } from "../utils/utils";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";
import { network, networkConfig } from "../utils/config";
import useClients from "../hooks/useClients";
import { metaMaskConnector, urlConnector } from "../utils/connectors";
import LocalDatabase from "../clients/LocalDatabase";
import { BigNumber, providers } from "ethers";

const ImxContext = createContext<any>(undefined);
let loginResolve: (() => void) | undefined = undefined;
let refreshLock = false;

export const ImxProvider: FunctionComponent<{
  localDatabase: LocalDatabase,
  children: ReactNode,
}> = ({ localDatabase, children }) => {
  const { account, connector, provider, chainId } = useWeb3React();

  /* Loading */

  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState<string>();

  const actionHandler = async (action: () => Promise<unknown>) => {
    setLoading(true);
    setLoadingMessage(undefined);
    try {
      await action();
    } catch (error) {
      handleError(error);
    } finally {
      setLoading(false);
      setLoadingMessage(undefined);
    }
  };

  /* Account */

  const [storedAccount, setStoredAccount] = useState<string>();
  const [imxRegistered, setImxRegistered] = useState(false);
  const [imxUnlocked, setImxUnlocked] = useState(false);
  const [loginModal, setLoginModal] = useState(false);
  const [impersonate, setImpersonate] = useState();

  const setAndStoreAccount = (storedAccount: string, imxUnlocked: boolean) => {
    typeof localStorage !== `undefined` && localStorage.setItem("user_account", storedAccount);
    setStoredAccount(storedAccount);
    setImxUnlocked(imxUnlocked);
  };
  const anyAccount = impersonate || account || storedAccount;
  const loginFlags = useMemo(
    () => ({
      accountStored: !!storedAccount,
      ethereumConnected: !!account,
      imxRegistered: imxRegistered,
      imxUnlocked: imxUnlocked,
    }),
    [storedAccount, account, imxRegistered, imxUnlocked]
  );

  /* Account balance */

  const [ogHolderProof, setOgHolderProof] = useState<number>();
  const [paymentAt, setPaymentAt] = useState<"eth"|"imx">("eth");
  const [paymentCabbage, setPaymentCabbage] = useState<"unpacked"|"packed">("unpacked");
  const [balanceL1, setBalanceL1] = useState<BigNumber>();
  const [balanceL2, setBalanceL2] = useState<BigNumber>();
  const balance = balanceL1 && balanceL2 && (paymentAt === "eth" ? balanceL1 : balanceL2);

  const setBalance = (balance: BigNumber) => {
    if (paymentAt === "eth") {
      setBalanceL1(balance);
    } else {
      setBalanceL2(balance);
    }
  };


  /* Clients */

  const [client, setClient] = useState<ImmutableXClient>();
  const link = new Link(networkConfig.imx_link_url);
  const clients = useClients({
    account: anyAccount,
    link,
    client,
    connector,
    provider,
    localDatabase,
    paymentAt,
    paymentCabbage,
    balance,
    balanceL1,
    balanceL2,
    ogHolderProof,
    impersonating: !!impersonate,
    setAndStoreAccount,
    setLoadingMessage,
    setBalance,
  });
  const {
    ethereumClient,
    imxClient,
    assetClient,
    assetActionsClient,
    eventClient,
    rewardClient,
    decksClient,
    tokensClient,
    farmingClient,
  } = clients;

  useEffect(() => {
    const initialize = async () => {
      const storedAccount = typeof localStorage !== `undefined` && localStorage.getItem("user_account");
      if (storedAccount) {
        setStoredAccount(storedAccount);
      }

      const client = await ImmutableXClient.build({
        publicApiUrl: networkConfig.imx_env_url,
      });
      setClient(client);

      await urlConnector.activate();
    };
    initialize().catch(handleError);
  }, [networkConfig]);

  useEffect(() => {
    const initializeRegistered = async () => {
      let imxRegistered =
        typeof localStorage !== `undefined` && localStorage.getItem(`imx_${anyAccount}_registered`) === "true";
      if (!imxRegistered) {
        imxRegistered = await client!.isRegistered({ user: anyAccount! });
      }
      if (imxRegistered) {
        typeof localStorage !== `undefined` && localStorage.setItem(`imx_${anyAccount}_registered`, "true");
      }
      setImxRegistered(imxRegistered);
      setImxUnlocked(imxRegistered);
    };
    if (client) {
      if (anyAccount) {
        initializeRegistered().catch(handleError);
      } else {
        setImxRegistered(false);
      }
    }
  }, [client, anyAccount]);

  useEffect(() => {
    const initAssets = async () => {
      //init from cache
      const ogHolderProofCache = await assetClient.getOriginalBunnyHolderProof(anyAccount!);
      setOgHolderProof(ogHolderProofCache);

      const refreshAllTask = Promise.all([
        assetClient.refreshAssets(anyAccount!),
        eventClient.refreshEvents(),
        rewardClient.refreshRewards(anyAccount!),
        tokensClient.refreshTokens(),
        assetActionsClient.finalizeMintJournal(anyAccount!),
        decksClient.refreshDecks(anyAccount!),
        farmingClient.refreshFarmingPlayer(anyAccount!),
      ]);

      toast.promise(refreshAllTask, {
        pending: "Refreshing owned assets",
        success: {
          render() {
            return "Done";
          },
          autoClose: 100,
        },
        error: "Asset refresh failed! Displayed information may be outdated.",
      });
      await refreshAllTask;

      // init from refreshed
      const ogHolderProof = await assetClient.getOriginalBunnyHolderProof(anyAccount!);
      setOgHolderProof(ogHolderProof);
    };

    const initAssetsDisconnected = async () => {
      //init from cache
      await Promise.all([eventClient.refreshEvents(), tokensClient.refreshTokens()]);
    };

    if (client) {
      if (!refreshLock) {
        refreshLock = true;
        if (anyAccount) {
          initAssets()
            .catch(handleError)
            .finally(() => {
              refreshLock = false;
            });
        } else {
          initAssetsDisconnected()
            .catch(handleError)
            .finally(() => {
              refreshLock = false;
            });
        }
      }

      const refreshSchedule = 1000 * 60 * 60 * 6 + Math.round(1000 * 60 * 60 * 2 * Math.random()); // 6-8 hours
      const refreshInterval = setInterval(() => {
        if (!refreshLock) {
          if (anyAccount) {
            initAssets()
              .catch(handleError)
              .finally(() => {
                refreshLock = false;
              });
          } else {
            initAssetsDisconnected()
              .catch(handleError)
              .finally(() => {
                refreshLock = false;
              });
          }
        }
      }, refreshSchedule);
      return () => {
        clearInterval(refreshInterval);
      };
    }
  }, [client, anyAccount]);

  useEffect(() => {
    const initializeBalances = async () => {
      const balanceL1 = await ethereumClient.getBalance();
      setBalanceL1(balanceL1);

      const balanceL2 = await imxClient.getBalance();
      setBalanceL2(balanceL2);
    };
    if (anyAccount && provider) {
      initializeBalances().catch(handleError);
    } else {
      setBalanceL1(undefined);
      setBalanceL2(undefined);
    }
  }, [anyAccount, provider]);

  useEffect(() => {
    if (account) {
      setAndStoreAccount(account, storedAccount === account);
    }
  }, [account]);

  useEffect(() => {
    if (chainId && chainId != networkConfig.chain_id) {
      handleError(new Error(`Invalid chain id: ${chainId}`));
    }
  }, [chainId]);

  const resetLocalData = async () => {
    const clearAllTask = localDatabase.clearAll();

    toast.promise(clearAllTask, {
      pending: "Clearing local data",
      success: {
        render() {
          return "Done";
        },
        autoClose: 140,
      },
      error: "Local data clearing failed!",
    });
    await clearAllTask;

    const refreshAllTask = Promise.all([
      assetClient.refreshAssets(anyAccount!),
      eventClient.refreshEvents(),
      rewardClient.refreshRewards(anyAccount!),
      tokensClient.refreshTokens(),
      decksClient.refreshDecks(anyAccount!),
      farmingClient.refreshFarmingPlayer(anyAccount!),
    ]);

    toast.promise(refreshAllTask, {
      pending: "Refreshing owned assets",
      success: {
        render() {
          return "Done";
        },
        autoClose: 140,
      },
      error: "Asset refresh failed! Displayed information may be outdated.",
    });
    await refreshAllTask;

    // init from refreshed
    const ogHolderProof = await assetClient.getOriginalBunnyHolderProof(anyAccount!);
    setOgHolderProof(ogHolderProof);
  };

  const connectEthereum = () => {
    actionHandler(ethereumClient.connect.bind(ethereumClient));
  };

  const disconnect = () => {
    const task = async () => {
      typeof localStorage !== `undefined` && localStorage.removeItem("user_account");
      setStoredAccount(undefined);
      if (metaMaskConnector?.deactivate) {
        await metaMaskConnector.deactivate();
      } else {
        await metaMaskConnector.resetState();
      }
    };
    actionHandler(task);
  };

  // don't ask me how this works, assume it's magic
  const [requestedFlags, setRequestedFlags] = useState();
  const isLoginFulfilled = (loginFlags: any, requestedFlags: any) => {
    let fulfilled = true;
    if (requestedFlags.accountStored && !loginFlags.accountStored) {
      fulfilled = false;
    }
    if (requestedFlags.ethereumConnected && !loginFlags.ethereumConnected) {
      fulfilled = false;
    }
    if (requestedFlags.imxRegistered && !loginFlags.imxRegistered) {
      fulfilled = false;
    }
    if (requestedFlags.imxUnlocked && !loginFlags.imxUnlocked) {
      fulfilled = false;
    }
    return fulfilled;
  };
  const ensureLogin = (requestedFlags: any) => {
    if (isLoginFulfilled(loginFlags, requestedFlags)) {
      const loginPromise = new Promise<void>((resolve, reject) => {
        resolve();
      });
      return loginPromise;
    } else {
      const loginPromise = new Promise<void>((resolve, reject) => {
        loginResolve = resolve;
      });
      setLoginModal(true);
      setRequestedFlags(requestedFlags);
      return loginPromise;
    }
  };
  useEffect(() => {
    if (requestedFlags && loginResolve) {
      if (isLoginFulfilled(loginFlags, requestedFlags)) {
        loginResolve();
        setLoginModal(false);
        setRequestedFlags(undefined);
        loginResolve = undefined;
      }
    }
  }, [requestedFlags, loginFlags]);

  return (
    <ImxContext.Provider
      value={{
        network,

        loading,
        loadingMessage,
        setLoadingMessage,
        actionHandler,

        account: anyAccount,
        loginFlags,
        connectEthereum,
        disconnect,
        ensureLogin,
        resetLocalData,
        impersonate,
        setImpersonate,

        paymentAt,
        setPaymentAt,
        paymentCabbage,
        setPaymentCabbage,
        balanceL1,
        balanceL2,
        ogHolderProof,
        setBalanceL1,
        setBalanceL2,
        balance,

        link,
        client,
        provider,
        ...clients,
        localDatabase,
      }}
    >
      {children}
      {loginModal && (
        <Modal title="Authorize wallet" onClose={() => setLoginModal(false)}>
          <ImxLogin />
        </Modal>
      )}
    </ImxContext.Provider>
  );
};

export const useImx = (): {
  network: string,

  loading: boolean,
  loadingMessage: string,
  setLoadingMessage: () => string,
  actionHandler: () => Promise<unknown>,

  account: string,
  loginFlags: {
    accountStored: boolean,
    ethereumConnected: boolean,
    imxRegistered: boolean,
    imxUnlocked: boolean,
  },
  connectEthereum: () => void,
  disconnect: () => void,
  ensureLogin: () => void,
  resetLocalData: () => void,
  impersonate: string|undefined,
  setImpersonate: (account: string) => void,

  paymentAt: "imx"|"eth",
  setPaymentAt: (at: "imx"|"eth") => void,
  paymentCabbage: "packed"|"unpacked",
  setPaymentCabbage: (cabbage: "packed"|"unpacked") => void,
  balanceL1: BigNumber|undefined,
  balanceL2: BigNumber|undefined,
  ogHolderProof: number|undefined,
  setBalanceL1: (b: BigNumber|undefined) => void,
  setBalanceL2: (b: BigNumber|undefined) => void,
  balance: BigNumber|undefined,

  link: Link,
  client: ImmutableXClient
  provider: providers.Provider,
  localDatabase: LocalDatabase,
} & ReturnType<typeof useClients> => {
  const imx = useContext(ImxContext);
  if(!imx) throw new Error("Missing context");
  return imx;
}

export default ImxContext;
