import LocalDatabase from "./LocalDatabase";
import PawWarzApiClient from "./PawWarzApiClient";

const REWARD_REFRESH_INTERVAL = 1000 * 60 * 60 * 6; // 6 hours

class RewardClient {
  localDatabase: LocalDatabase;
  pawWarzApiClient: PawWarzApiClient;

  constructor(localDatabase: LocalDatabase, pawWarzApiClient: PawWarzApiClient) {
    this.localDatabase = localDatabase;
    this.pawWarzApiClient = pawWarzApiClient;
  }

  async refreshRewards(account: string) {
    const version = (await this.localDatabase.getRewardsVersion(account)) || 0;
    if (Date.now() > version + REWARD_REFRESH_INTERVAL) {
      const rewards = await this.pawWarzApiClient.getPlayerRewards(account);
      await this.localDatabase.refreshRewards(rewards, account);
    }
  }

  async getRewards(account: string) {
    return await this.localDatabase.getRewards(account);
  }
}

export default RewardClient;
