import EthereumClient from "./EthereumClient";
import LocalDatabase from "./LocalDatabase";
import ImxClient from "./ImxClient";
import FarmingClient from "./FarmingClient";
import { ImxTransferRequest } from "./ImxClient";
import { Context, UnifiedAsset, PaymentTxs, PaymentTx, MintEntry, Actions, PaymentTxStatus } from "./types";
import { BigNumber } from "ethers";
import { gatherPaymentStatuses, gatherPaymentTxs, sleep } from "../utils/utils";

type ImxPaymentRequest = ImxTransferRequest & {
  txType: keyof [PaymentTxs];
  assetIndex?: number;
};

type InitiatePaymentContext = {
  mintId: string;
  txs: PaymentTxs;
  imxTransfers: Array<ImxPaymentRequest>;
  cabbageAssets?: Array<UnifiedAsset>;
  forceUnpackedCabbage?: boolean;
  forcePackedCabbage?: boolean;
};

type ConcludePaymentContext = {
  mintId: string;
  txs: PaymentTxs;
};

class PaymentClient {
  context: Context;
  actions: Actions;
  localDatabase: LocalDatabase;
  ethereumClient: EthereumClient;
  imxClient: ImxClient;
  farmingClient: FarmingClient;

  constructor(
    context: Context,
    actions: Actions,
    localDatabase: LocalDatabase,
    ethereumClient: EthereumClient,
    imxClient: ImxClient,
    farmingClient: FarmingClient
  ) {
    this.context = context;
    this.actions = actions;
    this.localDatabase = localDatabase;
    this.ethereumClient = ethereumClient;
    this.imxClient = imxClient;
    this.farmingClient = farmingClient;
  }

  async wrapInitiatePaymentTx(
    txType: keyof [PaymentTxs],
    assetIndex: number | undefined,
    context: InitiatePaymentContext,
    requestPayment: () => Promise<string>
  ): Promise<void> {
    const paymentTx = (assetIndex == undefined ? context.txs[txType] : context.txs[txType][assetIndex]) as PaymentTx;

    if (paymentTx.status == "new" || paymentTx.status == "failed") {
      try {
        const tx = await requestPayment();
        paymentTx.tx = tx;
        paymentTx.status = "pending";
        await this.localDatabase.updateMintJournalEntryPaymentTxs(context.mintId, context.txs, this.context.account);
      } catch (e) {
        paymentTx.status = "failed";
        await this.localDatabase.updateMintJournalEntryPaymentTxs(context.mintId, context.txs, this.context.account);
        throw e;
      }
    }
  }

  async wrapImxTransfer(
    txType: keyof [PaymentTxs],
    assetIndex: number | undefined,
    context: InitiatePaymentContext,
    transferRequest: ImxTransferRequest
  ): Promise<string> {
    context.imxTransfers.push({
      txType,
      assetIndex,
      ...transferRequest,
    });

    while (true) {
      const paymentTx = (assetIndex == undefined ? context.txs[txType] : context.txs[txType][assetIndex]) as PaymentTx;
      if (paymentTx.status != "new") {
        if (paymentTx.tx) {
          return paymentTx.tx;
        } else {
          throw new Error("Transaction unsuccessful");
        }
      } else {
        await sleep(500);
      }
    }
  }

  initiatePaymentEth(eth: BigNumber, context: InitiatePaymentContext): Promise<void> {
    return this.wrapInitiatePaymentTx("eth" as keyof [PaymentTxs], undefined, context, async () => {
      if (this.context.paymentAt === "imx") {
        return this.wrapImxTransfer("eth" as keyof [PaymentTxs], undefined, context, this.imxClient.preparePayFor(eth));
      } else if (this.context.paymentAt === "eth") {
        return this.ethereumClient.payFor(eth);
      } else {
        throw new Error("Unknown payment method");
      }
    });
  }

  initiatePaymentCabbage(cabbage: number, context: InitiatePaymentContext): Promise<void> {
    const packed =
      context.forcePackedCabbage || context.forceUnpackedCabbage
        ? context.forcePackedCabbage
        : this.context.paymentCabbage == "packed";

    if (packed) {
      let cabbageAssetsValue = 0;
      if (!context.cabbageAssets) {
        throw new Error("No cabbage assets provided");
      }
      const cabbageTxsPromises: Array<Promise<void>> = [];
      for (let i = 0; i < context.cabbageAssets.length; i++) {
        const cabbageAsset = context.cabbageAssets[i];
        if (cabbageAsset.type != "cabbage") {
          throw new Error("Invalid asset type");
        }
        cabbageAssetsValue += cabbageAsset.typeId * 50;

        // cabbage txs are not fully prefilled
        if (!context.txs["cabbage" as keyof [PaymentTxs]][i]) {
          context.txs["cabbage" as keyof [PaymentTxs]][i] = {
            status: "new",
          };
        }

        cabbageTxsPromises.push(
          this.wrapInitiatePaymentTx("cabbage" as keyof [PaymentTxs], i, context, async () => {
            return this.wrapImxTransfer(
              "cabbage" as keyof [PaymentTxs],
              i,
              context,
              this.imxClient.prepareBurn(cabbageAsset.tokenId)
            );
          })
        );
      }
      if (cabbageAssetsValue != cabbage) {
        throw new Error("Invalid cabbage assets provided");
      }
      return Promise.all(cabbageTxsPromises).then(() => {});
    } else {
      return this.wrapInitiatePaymentTx("cabbage" as keyof [PaymentTxs], 0, context, async () => {
        return this.farmingClient.useCabbage(cabbage).then((result) => result.actionId);
      });
    }
  }

  initiatePaymentAsset(asset: UnifiedAsset, assetIndex: number, context: InitiatePaymentContext): Promise<void> {
    return this.wrapInitiatePaymentTx("assets" as keyof [PaymentTxs], assetIndex, context, async () => {
      if (["pack", "card", "spell", "boss", "cabbage"].includes(asset.type)) {
        return this.wrapImxTransfer(
          "assets" as keyof [PaymentTxs],
          assetIndex,
          context,
          this.imxClient.prepareBurn(asset.tokenId)
        );
      } else if (asset.type == "farm") {
        return this.ethereumClient.burnFarm(asset.tokenId);
      } else {
        throw new Error("Asset type not supported");
      }
    });
  }

  initiatePaymentFuseFarm(farms: Array<UnifiedAsset>, eth: BigNumber, context: InitiatePaymentContext): Promise<void> {
    return this.wrapInitiatePaymentTx("fuseFarms" as keyof [PaymentTxs], undefined, context, async () => {
      if (farms.some((farm) => farm.type != "farm")) {
        throw new Error("Invalid asset type");
      }
      return this.ethereumClient.fuseFarms(
        farms.map((farm) => farm.tokenId),
        eth
      );
    });
  }

  initiatePaymentOnyxShards(onyxShards: number, context: InitiatePaymentContext): Promise<void> {
    return this.wrapInitiatePaymentTx("onyxShards" as keyof [PaymentTxs], undefined, context, async () => {
      return this.farmingClient.useOnyxShards(onyxShards).then((result) => result.actionId);
    });
  }

  initiatePaymentGoldNuggets(goldNuggets: number, context: InitiatePaymentContext): Promise<void> {
    return this.wrapInitiatePaymentTx("goldNuggets" as keyof [PaymentTxs], undefined, context, async () => {
      return this.farmingClient.useGoldNuggets(goldNuggets).then((result) => result.actionId);
    });
  }

  initiatePaymentUncutDiamonds(uncutDiamonds: number, context: InitiatePaymentContext): Promise<void> {
    return this.wrapInitiatePaymentTx("uncutDiamonds" as keyof [PaymentTxs], undefined, context, async () => {
      return this.farmingClient.useUncutDiamonds(uncutDiamonds).then((result) => result.actionId);
    });
  }

  async initiatePaymentAllImxTransfers(
    imxTransfers: Array<ImxPaymentRequest>,
    context: InitiatePaymentContext
  ): Promise<void> {
    // request all Immutable X transfers at one
    const txs = await this.imxClient.transferRequest(imxTransfers);

    for (let i = 0; i < imxTransfers.length; i++) {
      const imxTransfer = imxTransfers[i];
      const paymentTx = (
        imxTransfer.assetIndex == undefined
          ? context.txs[imxTransfer.txType]
          : context.txs[imxTransfer.txType][imxTransfer.assetIndex]
      ) as PaymentTx;
      paymentTx.tx = txs[i];
      paymentTx.status = "pending";
    }

    await this.localDatabase.updateMintJournalEntryPaymentTxs(context.mintId, context.txs, this.context.account);
  }

  async initiatePayment(
    mint: MintEntry,
    paymentOptions: {
      cabbageAssets?: Array<UnifiedAsset>;
      forceUnpackedCabbage?: boolean;
      forcePackedCabbage?: boolean;
    } = {}
  ): Promise<Array<string>> {
    const txsPromises: Array<Promise<void>> = [];
    const imxTransfers: Array<ImxPaymentRequest> = [];
    const context = {
      mintId: mint.id,
      txs: mint.txs,
      imxTransfers,
      ...paymentOptions,
    };

    if (mint.payment.eth) {
      txsPromises.push(this.initiatePaymentEth(mint.payment.eth, context));
    }

    if (mint.payment.cabbage && mint.payment.cabbage > 0) {
      txsPromises.push(this.initiatePaymentCabbage(mint.payment.cabbage, context));
    }

    if (mint.payment.assets) {
      for (let i = 0; i < mint.payment.assets.length; i++) {
        txsPromises.push(this.initiatePaymentAsset(mint.payment.assets[i], i, context));
      }
    }

    if (mint.payment.fuseFarms) {
      txsPromises.push(this.initiatePaymentFuseFarm(mint.payment.fuseFarms.farms, mint.payment.fuseFarms.eth, context));
    }

    if (mint.payment.onyxShards && mint.payment.onyxShards > 0) {
      txsPromises.push(this.initiatePaymentOnyxShards(mint.payment.onyxShards, context));
    }

    if (mint.payment.goldNuggets && mint.payment.goldNuggets > 0) {
      txsPromises.push(this.initiatePaymentGoldNuggets(mint.payment.goldNuggets, context));
    }

    if (mint.payment.uncutDiamonds && mint.payment.uncutDiamonds > 0) {
      txsPromises.push(this.initiatePaymentUncutDiamonds(mint.payment.uncutDiamonds, context));
    }

    if (imxTransfers.length > 0) {
      txsPromises.push(this.initiatePaymentAllImxTransfers(imxTransfers, context));
    }

    // wait until we initiate all transactions
    // even if some fail reject only after all are initiated
    const results = await Promise.allSettled(txsPromises);
    for (const result of results) {
      if (result.status == "rejected") {
        throw result.reason;
      }
    }

    return gatherPaymentTxs(mint.txs);
  }

  async concludePaymentEth(
    eth: BigNumber,
    assetIndex: number | undefined,
    context: ConcludePaymentContext
  ): Promise<void> {
    const txType = "eth" as keyof [PaymentTxs];
    const paymentTx = (assetIndex == undefined ? context.txs[txType] : context.txs[txType][assetIndex]) as PaymentTx;

    if (paymentTx.status == "pending") {
      await this.waitForTransaction(paymentTx.tx!);
      paymentTx.status = "confirmed";

      // Update user's balance
      // TODO replace with propper balance tracking
      this.actions.setBalance(this.context.balance.sub(eth));
    }
  }

  async concludePaymentTx(
    txType: keyof [PaymentTxs],
    assetIndex: number | undefined,
    context: ConcludePaymentContext
  ): Promise<void> {
    const paymentTx = (assetIndex == undefined ? context.txs[txType] : context.txs[txType][assetIndex]) as PaymentTx;

    if (paymentTx.status == "pending") {
      await this.waitForTransaction(paymentTx.tx!);
      paymentTx.status = "confirmed";
    }
  }

  async concludePayment(mint: MintEntry): Promise<void> {
    const txsPromises: Array<Promise<void>> = [];
    const context = {
      mintId: mint.id,
      txs: mint.txs,
    };

    if (mint.payment.eth) {
      txsPromises.push(this.concludePaymentEth(mint.payment.eth, undefined, context));
    }

    if (mint.payment.cabbage && mint.payment.cabbage > 0) {
      for (let i = 0; i < mint.txs.cabbage!.length; i++) {
        txsPromises.push(this.concludePaymentTx("cabbage" as keyof [PaymentTxs], i, context));
      }
    }

    if (mint.payment.assets) {
      for (let i = 0; i < mint.payment.assets.length; i++) {
        txsPromises.push(this.concludePaymentTx("assets" as keyof [PaymentTxs], i, context));
      }
    }

    if (mint.payment.fuseFarms) {
      txsPromises.push(this.concludePaymentTx("fuseFarms" as keyof [PaymentTxs], undefined, context));
    }

    if (mint.payment.onyxShards && mint.payment.onyxShards > 0) {
      txsPromises.push(this.concludePaymentTx("onyxShards" as keyof [PaymentTxs], undefined, context));
    }

    if (mint.payment.goldNuggets && mint.payment.goldNuggets > 0) {
      txsPromises.push(this.concludePaymentTx("goldNuggets" as keyof [PaymentTxs], undefined, context));
    }

    if (mint.payment.uncutDiamonds && mint.payment.uncutDiamonds > 0) {
      txsPromises.push(this.concludePaymentTx("uncutDiamonds" as keyof [PaymentTxs], undefined, context));
    }

    // conclude all transactions
    await Promise.all(txsPromises);

    await this.localDatabase.updateMintJournalEntryPaymentTxs(context.mintId, context.txs, this.context.account);

    // check if all transactions are confirmed
    const paymentStates = gatherPaymentStatuses(context.txs);
    const wrongStates = paymentStates
      .filter((state) => state != "confirmed")
      .reduce((a: Array<PaymentTxStatus>, b) => (a.includes(b) ? a : [...a, b]), []);
    if (wrongStates.length > 0) {
      throw new Error(`Payment transactions in a wrong state: ${wrongStates.join(", ")}`);
    }
  }

  async waitForTransaction(tx: string): Promise<void> {
    if (tx.match(/^\d+$/)) {
      // Immutable X
      await this.imxClient.waitForTransferProcessed(tx);
    } else if (tx.match(/^0x[0-9a-fA-F]{64}$/)) {
      // Ethereum
      await this.ethereumClient.waitForTransaction(tx);
    } else if (tx.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/)) {
      // Farming action
      // ~Confirmed immediately~
    } else {
      throw new Error("Unknown transaction type");
    }
  }
}

export default PaymentClient;
