import { ETHTokenType, ERC20TokenType, ERC721TokenType, ImmutableTransactionStatus } from "@imtbl/imx-sdk";
import { collection, marketplace, networkConfig } from "../utils/config";
import { BigNumber, utils } from "ethers";
import { sleep, getUsingCursor } from "../utils/utils";
import { Context, Actions, Token } from "./types";
import moment from "moment";
import axios from "axios";

const NULL_ADDRESS = "0x0000000000000000000000000000000000000000";

// HACK because ImmutableXClient does not have a method to access /mints endpoint
const hackListMints = async (params): Promise<{ result: Array<any>; cursor: string | undefined }> => {
  const res = await axios.get(networkConfig.imx_env_url + "/mints", {
    params: params,
  });
  return res.data;
};

export type ImxTransferRequest =
  | {
      type: ERC721TokenType;
      tokenId: string;
      tokenAddress: string;
      toAddress: string;
    }
  | {
      type: ETHTokenType;
      amount: string;
      toAddress: string;
    };

class ImxClient {
  context: Context;
  actions: Actions;

  constructor(context: Context, actions: Actions) {
    this.context = context;
    this.actions = actions;
  }

  async connect(): Promise<void> {
    const res = await this.context.link.setup({});
    if (this.context.account && res.address.toLowerCase() !== this.context.account.toLowerCase()) {
      throw new Error("You unlocked a different wallet");
    } else {
      this.actions.setAndStoreAccount(res.address, true);
    }
  }

  async getBalance(): Promise<BigNumber> {
    const balanceL2Object = await this.context.client.getBalance({
      user: this.context.account,
      tokenAddress: "eth",
    });
    return balanceL2Object.balance;
  }

  async getAllAssets(account: string): Promise<any> {
    return await getUsingCursor<any>(this.context.client.getAssets, {
      user: account,
      collection: collection,
    });
  }

  async getAsset(tokenId: number): Promise<any> {
    return await this.context.client.getAsset({
      address: collection,
      id: tokenId.toString(),
      include_fees: false,
    });
  }

  async getTransfersIn(
    sinceTimestamp: number,
    untilTimestamp: number,
    sender: string | undefined,
    receiver: string | undefined
  ): Promise<Array<any>> {
    return await getUsingCursor<any>(this.context.client.getTransfers, {
      token_address: collection,
      min_timestamp: sinceTimestamp && moment(sinceTimestamp).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      max_timestamp: untilTimestamp && moment(untilTimestamp).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      user: sender?.toLowerCase(),
      receiver: receiver?.toLowerCase(),
    });
  }

  async getMintsIn(sinceTimestamp: number, untilTimestamp: number, receiver: string | undefined): Promise<Array<any>> {
    return await getUsingCursor<any>(hackListMints, {
      token_address: collection,
      min_timestamp: sinceTimestamp && moment(sinceTimestamp).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      max_timestamp: untilTimestamp && moment(untilTimestamp).format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
      user: receiver?.toLowerCase(),
    });
  }

  async getTokens(): Promise<Array<Token>> {
    return await getUsingCursor<Token>(this.context.client.listTokens, {});
  }

  async waitForTransferProcessed(transferId: string): Promise<void> {
    const check = async () => {
      try {
        await this.context.client.getTransfer({ id: parseInt(transferId, 10) });
        return;
      } catch (err) {
        if (err.toString().includes("resource_not_found_code")) {
          await sleep(1500);
          await check();
        } else {
          throw err;
        }
      }
    };
    await check();
  }

  preparePayFor(price: BigNumber): ImxTransferRequest {
    return {
      amount: utils.formatEther(price),
      type: ETHTokenType.ETH,
      toAddress: networkConfig.imx_sales_wallet,
    };
  }

  async payFor(price: BigNumber): Promise<string> {
    if (this.context.impersonating) {
      throw new Error("Cannot sign transaction while impersonating");
    }

    const transferResult = await this.context.link.transfer([this.preparePayFor(price)]);

    return transferResult.result[0]["txId"].toString();
  }

  prepareBurn(tokenId: number): ImxTransferRequest {
    return {
      type: ERC721TokenType.ERC721,
      tokenAddress: collection,
      tokenId: tokenId.toString(),
      toAddress: NULL_ADDRESS,
    };
  }

  async burn(tokenId: number): Promise<string> {
    const txs = await this.transferRequest([this.prepareBurn(tokenId)]);
    return txs[0];
  }

  async burnAll(tokenIds: Array<number>): Promise<Array<string>> {
    return await this.transferRequest([...tokenIds.map((tokenId) => this.prepareBurn(tokenId))]);
  }

  async transferRequest(transfers: Array<ImxTransferRequest>): Promise<Array<string>> {
    if (this.context.impersonating) {
      throw new Error("Cannot sign transaction while impersonating");
    }

    const transferResult = await this.context.link.transfer(transfers);

    const txs: Array<string> = [];
    const foundTokenIds: Array<number> = [];
    transferResult.result.forEach((transfer) => {
      if (transfer["txId"]) {
        txs.push(transfer["txId"].toString());
        foundTokenIds.push(parseInt(transfer["tokenId"] as string));
      }
    });

    // HACK try to look for txs if they are missing, because ImmuableX sometimes doesn't return all txs, weird
    const missingTokenIds: Array<number> = [];
    for (let transfer of transfers) {
      if (transfer.type == ERC721TokenType.ERC721) {
        const tokenId = parseInt(transfer.tokenId);
        if (!foundTokenIds.includes(tokenId)) {
          missingTokenIds.push(tokenId);
        }
      }
    }
    for (let missingTokenId of missingTokenIds) {
      await sleep(1300);
      const transfers = await getUsingCursor(this.context.client.getTransfers, {
        user: this.context.account,
        receiver: NULL_ADDRESS,
        status: ImmutableTransactionStatus.success,
        token_type: ERC721TokenType.ERC721,
        token_id: missingTokenId.toString(),
        token_address: collection,
      });
      if (transfers.length == 1) {
        txs.push(transfers[0].transaction_id.toString());
      } else {
        throw new Error(`Failed to find burn tx for token ${missingTokenId}`);
      }
    }

    return txs;
  }

  async buy(orderId: string): Promise<{ status: "success" } | { status: "error"; message: string }> {
    if (this.context.impersonating) {
      throw new Error("Cannot sign transaction while impersonating");
    }

    const response = await this.context.link.buy({
      orderIds: [orderId],
      fees: [
        {
          recipient: marketplace.feeRecipient,
          percentage: marketplace.feePercentage,
        },
      ],
    });
    return response.result[orderId];
  }

  async deposit(tokenAddress: string, amount: BigNumber): Promise<any> {
    if (this.context.impersonating) {
      throw new Error("Cannot sign transaction while impersonating");
    }

    if (tokenAddress !== "") {
      return await this.context.link.deposit({
        type: ERC20TokenType.ERC20,
        tokenAddress: tokenAddress,
        amount: utils.formatEther(amount),
      });
    } else {
      return await this.context.link.deposit({
        type: ETHTokenType.ETH,
        amount: utils.formatEther(amount),
      });
    }
  }

  async waitForDepositProcessed(initialBalance: BigNumber): Promise<void> {
    const check = async () => {
      const result = await this.context.client.getBalance({
        user: this.context.account,
        tokenAddress: "eth",
      });
      if (result.balance.lte(initialBalance)) {
        await sleep(4150);
        await check();
      }
    };
    await check();
  }
}

export default ImxClient;
